import React from 'react'
import CustonTabs from '../../components/tables/CustonTabs'
import OutboundShipments from './OutboundShipments'

const OutboundShipmentsTabs = () => {
  return (
    <CustonTabs
        tabs={
            [
                {
                    title: "Ordenes de envio",
                    component: <OutboundShipments />
                }
            ]
        }
    />
  )
}

export default OutboundShipmentsTabs