import React, {useEffect, useState} from 'react';
import {Card, Col} from "antd";
import CustomLineChart from "../../components/CustomLineChart";
import {useAuth0} from "../../hooks/react-auth0-spa";
import {axiosBase as axios} from "../../api/AxiosConfig";

function ProductsSelledCurrentMonth(props) {

    const [data, setData] = useState([])

    const { getTokenSilently, isAuthenticated } = useAuth0()

    const fetchData = async () => {
        const token = await getTokenSilently()
        const headers = { Authorization: `Bearer ${token}` }

        try {
            const response = await axios.get('/statistics/productsSelledCurrentMonth', { headers })
            if(response.data){
                setData(response.data)
            }
        } catch (error) {
            console.log('Error at ProductsSelledCurrentMonth: ', error)
        }
    }

    // console.log('Data: ', data)
    useEffect(() => {
        if(isAuthenticated) fetchData()
    },[getTokenSilently, isAuthenticated])//eslint-disable-line

    return (
        <Col span={16}>
            <Card title={<p style={{margin: '0', padding: '0', fontSize: '14px', fontWeight: 'bold'}}>Productos vendidos este mes</p>}>
                <CustomLineChart data={data ?? []} serieName="Cant. vendida este mes" type="area"/>
            </Card>
        </Col>
    );
}

export default ProductsSelledCurrentMonth;