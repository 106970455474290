import React, { useContext, useEffect, useState, useRef } from 'react'
// import { StateProps } from '../../constants/CountrySettings/countriesTypes'
import { allCountries } from '../countrySettings/allCountries'
// import { FormInstance } from 'antd'
import axios from 'axios'
import { Form, Select, Row, Col, notification, Input } from 'antd'
import { MapableCountriesSettings } from '../countrySettings/countriesSettings'
import { CountriesContext } from '../../../context/CountriesContext'
// import {
//   fetchedAddressesData,
//   fetchedPostalCodesData,
//   InputReturnData
// } from '../../interfaces/directions.interfaces'
import {
  LoadingOutlined,
  SearchOutlined,
  UpCircleOutlined,
  CloseOutlined,
  NumberOutlined
} from '@ant-design/icons'
// import { ApiContext } from '../../context/apiContext'

const InputCountriesContainer = (inputProps) => {
  // Global State and Variables
//   const { backendApiCall } = useContext(ApiContext)
  const { Search } = Input
  const { Option } = Select
  let inputTypingTimer
  const { currentSelectedCountry } = useContext(CountriesContext)
  const [countrySelected, setCountrySelected] = useState(
    inputProps.initialCountry2DigitCode
      ? inputProps.initialCountry2DigitCode
      : currentSelectedCountry
  )

  // Data Container Variables for States and Directions
  const [fetchedStates, setFetchedStates] = useState([])
  useState(undefined)
  const [fetchedDirections, setFetchedDirections] = useState({
    fullData: [],
    optimizedData: []
  })
  const [fetchedPostalCodes, setFetchedPostalCodes] = useState({
    fullData: {
      zip_code: '',
      country: {},
      state: {
        code: {}
      },
      locality: '',
      suburbs: [],
      coordinates: {},
      info: {},
      regions: {}
    },
    optimizedData: []
  })
  const [fetchedFavoriteAddresses, setFetchedFavoriteAddresses] = useState({
    fullData: [],
    optimizedData: []
  })
  // Drawers State Variable Managers
  const [drawerState, setDrawerState] = useState('Closed')
  const [innerListsState, setInnerListsState] = useState({
    postalCodes: 'Closed',
    userAddresses: 'Closed'
  })
  // Variable para validar si se selecciono el CP escrito
  const [onlyCp, setOnlyCp] = useState(false)

  // Text Input and Loading Fetch Variables
  const [inputedText, setInputedText] = useState(undefined)
  const [optionSelected, setOptionSelected] = useState(undefined)
  const [loadingStates, setLoadingStates] = useState({
    postalCodes: 'Init',
    userAddresses: 'Init',
    userFavoriteAddresses: 'Init'
  })

  //Cambio de label a valor (Input Countries)
  const [selectedValue, setSelectedValue] = useState('MX')

  const fetchUserAddresses = async (wordToFilter) => {
    setLoadingStates((prevLoadingStates) => {
      return { ...prevLoadingStates, userAddresses: 'Fetching' }
    })

    //Variable que filtra si hay o no hay CP completo (CP's Usuario Guardados)
    const wordToFilterDataSave = wordToFilter.split('~')[0]?.trim() || wordToFilter
    await axios.get(
      `${process.env.REACT_APP_NOT_DRENVIO_BACKEND_URL}/users/address/search?wordToFilter=${wordToFilterDataSave}`,
      { headers: { Authorization: `Bearer ${inputProps.token}` }}
    )
      .then((res) => {
        if (res.data.length !== 0) {
          const fetchedAddresses = res.data
          // setFetchedDirections(res.data.data)
          const optimizedFetchedAddresses = fetchedAddresses
            .filter((direction) => {
              return direction.country === countrySelected
            })
            .map((direction) => {
              return {
                selectValue: `${direction.postal_code} ~ ${direction.name} ~ ${direction.city} ${direction.state}`,
                idValue: `${direction.postal_code} ~ ${direction.name} ~ ${direction.city} ${direction.state} ~ ${direction._id}`
              }
            })

          setFetchedDirections({
            optimizedData: optimizedFetchedAddresses,
            fullData: fetchedAddresses
          })

          // Verificar si hay información adicional después del ~
          const additionalInfo = wordToFilter.split('~')[1]?.trim()
          // Si hay información adicional, seleccionar automáticamente la dirección correspondiente
          if (additionalInfo) {
            const matchingAddress = optimizedFetchedAddresses.find((address) =>
              address.selectValue.includes(additionalInfo)
            )
            if (matchingAddress) {
              setOptionSelected(matchingAddress.idValue)
              setDrawerState('Closed')
            }
          }
        }
      })
      .catch((err) => {
        console.error(err)
        // setFetchedDirections([])
        // resolve(0)
      })
      .finally(() => {
        setLoadingStates((prevLoadingStates) => {
          return { ...prevLoadingStates, userAddresses: 'Fetched' }
        })
      })
  }
  const fetchUserfavoriteAddress = async () => {
    setLoadingStates((prevLoadingStates) => {
      return { ...prevLoadingStates, userFavoriteAddresses: 'Fetching' }
    })

    await axios.get(
      `${process.env.REACT_APP_NOT_DRENVIO_BACKEND_URL}/users/address/favorites`,
      { headers: { Authorization: `Bearer ${inputProps.token}` }}
    )
      .then((res) => {
        if (res.data.length !== 0) {
          const fetchedAddresses = res.data
          // setFetchedDirections(res.data.data)
          const optimizedFetchedFavoriteAddresses = fetchedAddresses
            .filter((direction) => {
              return direction.country === countrySelected
            })
            .map((direction) => {
              return {
                selectValue: `${direction.postal_code} ~ ${direction.name} ~ ${direction.city} ${direction.state}`,
                idValue: `${direction.postal_code} ~ ${direction.name} ~ ${direction.city} ${direction.state} ~ ${direction._id}`
              }
            })
          setFetchedFavoriteAddresses({
            fullData: fetchedAddresses,
            optimizedData: optimizedFetchedFavoriteAddresses
          })
        }
      })
      .catch((err) => {
        console.error(err)
      })
      .finally(() => {
        setLoadingStates((prevLoadingStates) => {
          return { ...prevLoadingStates, userFavoriteAddresses: 'Fetched' }
        })
      })
  }
  const fetchPostalCodes = async (postalCode) => {
    postalCode = postalCode?.toString()?.replace(/\s|-/g, '');

    setLoadingStates((prevLoadingStates) => {
      return { ...prevLoadingStates, postalCodes: 'Fetching' }
    })

    //Variable que filtra si hay o no hay CP completo (CP's)
    const postalCodeDataSave = postalCode.split('~')[0]?.trim() || postalCode

    // @ts-ignore
    if (
      typeof (isNaN(Number(postalCodeDataSave)) ? null : +postalCodeDataSave) === 'number' &&
      postalCode.length >= 4
    ) {

      await axios
        .get(`https://geocodes.envia.com/zipcode/${countrySelected}/${postalCodeDataSave}`, {
          headers: {
            Authorization: `${process.env.REACT_APP_ENVIA_POSTAL_CODES_TOKEN}`
          }
        })
        .then((res) => {
          if (res.data[0]) {
            const fetchedData = res.data[0].suburbs
            const organizedFetchedData =
              fetchedData.length !== 0
                ? fetchedData.map((addressDirection) => {
                    return {
                      adminName1: res.data[0].regions.region_1,
                      adminName2: res.data[0].regions.region_2,
                      placeName: `~ ${addressDirection}`,
                      postalcode: res.data[0].zip_code
                    }
                  })
                : [
                    {
                      adminName1: res.data[0].regions.region_1,
                      adminName2: res.data[0].regions.region_2,
                      placeName: '',
                      postalcode: res.data[0].zip_code
                    }
                  ]
            const optimizedFetchedData = organizedFetchedData.map((entry) => {
              return {
                selectValue: `${entry.postalcode} ${entry.placeName} ~ ${entry.adminName2} (${entry.adminName1})`,
                idValue: `${entry.postalcode} ${entry.placeName} ~ ${entry.adminName2} (${entry.adminName1})`
              }
            })

            setFetchedPostalCodes({
              optimizedData: optimizedFetchedData,
              fullData: res.data[0]
            })
            // resolve(optimizedFetchedData.length)

            // Verificar si hay información adicional después del ~
            const additionalInfo = postalCode.split('~')[1]?.trim()

            // Si hay información adicional, seleccionar automáticamente la dirección correspondiente
            if (additionalInfo) {
              const matchingAddress = fetchedData.find((addressDirection) =>
                addressDirection.includes(additionalInfo)
              )
              if (matchingAddress) {
                setOptionSelected(matchingAddress.idValue)
                setDrawerState('Closed')
              }
            }
          } else {
            // resolve(0)
          }
        })
        .catch((err) => {
          console.error(err)
          // setFetchedPostalCodes({
          //   fullData: [],
          //   optimizedData: []
          // })
          // resolve(0)
        })
        .finally(() => {
          setLoadingStates((prevLoadingStates) => {
            return { ...prevLoadingStates, postalCodes: 'Fetched' }
          })
        })
    } else {
      setLoadingStates((prevLoadingStates) => {
        return { ...prevLoadingStates, postalCodes: 'Fetched' }
      })
      // resolve(0)
    }
  }

  const fetchCountryStates = async () => {
    await axios
      .get(`https://queries.envia.com/state?country_code=${countrySelected}`, {
        headers: {
          Authorization: `${process.env.REACT_APP_ENVIA_POSTAL_CODES_TOKEN}`
        }
      })
      .then((res) => {
        setFetchedStates(res.data.data)
        // console.log(res.data.data)
      })
      .catch((err) => {
        console.error(err)
      })
  }

  const clearPostalCodeInput = () => {
    setDrawerState('Closed')
    setOptionSelected(undefined)
    setInputedText(undefined)

    setLoadingStates({
      postalCodes: 'Init',
      userAddresses: 'Init',
      userFavoriteAddresses: 'Init'
    })
    setInnerListsState({
      postalCodes: 'Closed',
      userAddresses: 'Closed'
    })
    setFetchedPostalCodes({
      fullData: {
        zip_code: '',
        country: {},
        state: {
          code: {}
        },
        locality: '',
        suburbs: [],
        coordinates: {},
        info: {},
        regions: {}
      },
      optimizedData: []
    })
    setFetchedDirections({
      fullData: [],
      optimizedData: []
    })
    inputProps.userFormHook &&
      inputProps.userFormHook.setFieldsValue({
        [inputProps.valueContainerName]: undefined
      })
  }

  // Poner el Pais del Usuario como Inicial
  useEffect(() => {
    inputProps.userFormHook &&
      inputProps.userFormHook.setFieldsValue({
        [`${inputProps.valueContainerName}SelectedCountry`]: countrySelected
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // If initialPostalCode is passed, set it as the inputedText
  useEffect(() => {
    if (inputProps.initialPostalCode) {
      // clearPostalCodeInput()
      setInputedText(inputProps.initialPostalCode)
    }
  }, [inputProps.initialPostalCode]) //eslint-disable-line

  // Fetch Postal Codes , saved and favorite addresses
  useEffect(() => {
    if (inputedText) {
      inputProps.userFormHook?.setFieldsValue({
        [`${inputProps.valueContainerName}`]: inputedText
      })

      !inputProps.initialPostalCode && setDrawerState('Open')

      inputProps.onPostalCodeChange &&
        !inputProps.initialPostalCode &&
        inputProps.onPostalCodeChange({
          postalCode: inputedText,
          entireString: inputedText,
          addressType: 'ManualPC',
          onSelected: inputProps.valueContainerName
        })

      fetchPostalCodes(inputedText)
      fetchUserAddresses(inputedText)
      fetchUserfavoriteAddress()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputedText])

  // Estudy the optionSelected and return the data
  useEffect(() => {
    if (optionSelected) {
      inputProps.userFormHook?.setFieldsValue({
        [`${inputProps.valueContainerName}`]: optionSelected
      })
      // console.log(optionSelected)
      // Return data logic here
      const selectedAddressType = optionSelected
        ?.split('~')
        [optionSelected?.split('~').length - 1].trim()
        .includes(')')
        ? 'FetchedAddress'
        : 'SavedAddress'

      const selectedAddresID =
        selectedAddressType === 'SavedAddress' ? optionSelected.split('~')[3].trim() : 'none'

      const entireObjectData =
        selectedAddressType === 'FetchedAddress'
          ? fetchedPostalCodes.fullData
          : fetchedDirections?.fullData.filter(
              (address) => address._id?.trim() === selectedAddresID
            )[0] ||
            fetchedFavoriteAddresses?.fullData.filter(
              (address) => address._id?.trim() === selectedAddresID
            )[0]

      if (!entireObjectData) {
        notification.error({
          message: 'Hay un error con la dirección que seleccionaste, por favor utiliza otra'
        })
        clearPostalCodeInput()
      }

      // console.log(selectedAddressType)
      // console.log(entireObjectData)

      inputProps.onPostalCodeChange &&
        inputProps.onPostalCodeChange({
          postalCode: optionSelected?.split('~')[0].trim(),
          entireString: optionSelected,
          addressType: selectedAddressType,
          // @ts-ignore
          entireObject: entireObjectData,
          onSelected: inputProps.valueContainerName
        })
    } else {
      inputProps.userFormHook?.setFieldsValue({
        [`${inputProps.valueContainerName}`]: undefined
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionSelected])

  useEffect(() => {
    if (
      inputProps.type === 'allCountriesStatesInputs' ||
      inputProps.type === 'localCountriesStatesInputs'
    ) {
      fetchCountryStates()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countrySelected])

  const containerRef = useRef(null)

  useEffect(() => {
    // Función para manejar clics dentro del contenedor
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        // Si el clic ocurrió fuera del div, oculta el div.
        setDrawerState('Closed')
        if (
          fetchedPostalCodes.optimizedData.length > 0 &&
          optionSelected === undefined &&
          onlyCp === false
        ) {
          setOptionSelected(fetchedPostalCodes?.optimizedData[0].idValue)
        }
      }
    }

    // Agregar un event listener al documento para manejar clics dentro del contenedor
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Eliminar el event listener al desmontar el componente
      document.removeEventListener('mousedown', handleClickOutside)
    }
    // Sin dependencias, solo se agrega una vez
  }, [fetchedPostalCodes, optionSelected, onlyCp]) //eslint-disable-line

  const handleSelectChange = (value) => {
    setSelectedValue(value)
  }

  // Componente individual para representar cada elemento
  function PostalCodeItem({ postalCode, setOptionSelected, setDrawerState }) {
    const handleItemClick = () => {
      setOptionSelected(postalCode.idValue)
      setDrawerState('Closed')
    }

    return (
      <div className="smallDrawer" onClick={handleItemClick}>
        <p>{postalCode.selectValue}</p>
      </div>
    )
  }

  const getComponent = () => {
    switch (inputProps.type) {
      case 'allCountriesPostalCodeInputs':
      case 'localCountriesPostalCodesInputs':
        return (
          <Row>
            <Col xs={6} sm={6} md={6} lg={4} xl={4} xxl={4}>
              <Form.Item
                name={`${inputProps.valueContainerName}SelectedCountry`}
                rules={[
                  {
                    required: inputProps.required,
                    message: inputProps.errorMessage
                      ? inputProps.errorMessage
                      : 'campo incompleto'
                  }
                ]}
              >
                <Select
                  showSearch
                  value={countrySelected}
                  dropdownMatchSelectWidth={false}
                  style={{
                    width: '100%',
                    fontWeight: 'bolder',
                    ...inputProps.style
                  }}
                  onChange={(e) => {
                    handleSelectChange(e)
                    const countryResult = e
                    inputProps.onCountryChange && inputProps.onCountryChange(countryResult)

                    clearPostalCodeInput()
                    // setFetchedPostalCodes({
                    //   optimizedData: [],
                    //   fullData: []
                    // })
                    inputProps.valueContainerName &&
                      inputProps.userFormHook?.setFieldsValue({
                        [inputProps.valueContainerName]: undefined
                      })
                    setCountrySelected(e)
                  }}
                  // disabled={
                  //   inputProps.type === 'localCountriesPostalCodesInputs'
                  //     ? true
                  //     : inputProps.disabled
                  // }
                  placeholder={'Selecciona Un País'}
                  filterOption={(input, option) =>
                    // @ts-ignore
                    (option?.children[1])
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                >
                  {allCountries.map((country) => {
                    return (
                      <Option
                        key={country.value}
                        value={country.value}
                        defaultValue={currentSelectedCountry}
                      >
                        <span role="img">
                          <img
                            src={country.svgFlag}
                            alt={'d'}
                            style={{
                              width: '15px',
                              height: '15px',
                              marginRight: '5px',
                              marginBottom: '2.5px'
                            }}
                          />
                        </span>
                        {selectedValue === country.value ? country.value : country.label}
                      </Option>
                    )
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={18} sm={18} md={18} lg={20} xl={20} xxl={20}>
              <Row>
                <Col span={24}>
                  <Form.Item name={`${inputProps.valueContainerName}`}>
                    <Search
                      required
                      placeholder={inputProps.textPlaceHolder || ''}
                      // value={optionSelected ? optionSelected : inputedText}
                      onClick={() => setDrawerState('Open')}
                      onSearch={() => {
                        clearPostalCodeInput()
                      }}
                      enterButton={
                        <div
                          style={{
                            width: '100%',
                            height: '100%',
                            marginBottom: '5px',
                            cursor:
                              loadingStates.postalCodes === 'Init' &&
                              loadingStates.userAddresses === 'Init'
                                ? 'pointer'
                                : 'default'
                          }}
                        >
                          {loadingStates.postalCodes === 'Init' &&
                          loadingStates.userAddresses === 'Init' ? (
                            <SearchOutlined style={{ pointerEvents: 'none' }} />
                          ) : loadingStates.postalCodes === 'Fetching' ||
                            loadingStates.userAddresses === 'Fetching' ||
                            loadingStates.userFavoriteAddresses === 'Fetching' ? (
                            <LoadingOutlined style={{ pointerEvents: 'none' }} />
                          ) : (
                            <CloseOutlined style={{ pointerEvents: 'none' }} />
                          )}
                        </div>
                      }
                      onChange={(e) => {
                        if (optionSelected === undefined) {
                          // console.log(e.target.value)
                          clearTimeout(inputTypingTimer)
                          if (e.target.value !== '') {
                            inputTypingTimer = setTimeout(() => {
                              setInputedText(e.target.value)
                            }, 1200)
                          } else {
                            clearPostalCodeInput()
                          }
                        }
                      }}
                    />
                  </Form.Item>

                  <div
                    className={`listDrawer Drawer${drawerState} `}
                    style={{
                      height:
                        loadingStates.postalCodes === 'Init' &&
                        loadingStates.userAddresses === 'Init'
                          ? '90px'
                          : innerListsState.postalCodes === 'Open' ||
                            innerListsState.userAddresses === 'Open'
                          ? '250px'
                          : '150px'
                    }}
                  >
                    <div className="innerDrawerContainer" ref={containerRef}>
                      {loadingStates.postalCodes === 'Init' &&
                      loadingStates.userAddresses === 'Init' ? (
                        <p>
                          {
                            'Escribe un Código Postal o el nombre de Una Dirección Guardada para Buscarla'
                          }{' '}
                        </p>
                      ) : (
                        <div
                          className={`listsDrawerContainer Drawer${inputProps.valueContainerName}`}
                        >
                          {/* CPs Genericos de Envia */}
                          <div
                            className={`postalCodesListContainer ListDrawer${innerListsState.postalCodes}`}
                          >
                            {loadingStates.postalCodes === 'Fetching' ? (
                              <div
                                className="bigDrawer"
                                style={{ backgroundColor: 'rgba(59, 142, 226, 0.3)' }}
                              >
                                <LoadingOutlined style={{ marginRight: '10px' }} />
                                <p>{'Cargando Códigos Postales'}</p>
                              </div>
                            ) : (
                              <>
                                <div
                                  className="bigDrawer ReadyToOpen"
                                  style={{
                                    backgroundColor:
                                      fetchedPostalCodes.optimizedData.length > 0
                                        ? 'rgba(111, 115, 246, 0.3)'
                                        : 'rgba(251, 150, 150, 0.5)',
                                    width: '100%'
                                  }}
                                  onClick={() => {
                                    if (fetchedPostalCodes.optimizedData.length > 0) {
                                      setInnerListsState((prevListsState) => {
                                        return {
                                          ...prevListsState,
                                          postalCodes:
                                            prevListsState.postalCodes === 'Closed'
                                              ? 'Open'
                                              : 'Closed'
                                        }
                                      })
                                    }
                                  }}
                                >
                                  <UpCircleOutlined
                                    style={{
                                      marginRight: '5px',
                                      transition: 'transform 350ms',
                                      transform:
                                        innerListsState.postalCodes === 'Open'
                                          ? 'rotate(180deg)'
                                          : 'rotate(0deg)'
                                    }}
                                  />
                                  <p>
                                    {fetchedPostalCodes.optimizedData.length > 0
                                      ? 'Códigos Postales'
                                      : 'No se encontraron Códigos Postales'}
                                  </p>
                                </div>
                                {fetchedPostalCodes.optimizedData.map((postalCode) => (
                                  <PostalCodeItem
                                    key={postalCode.idValue}
                                    postalCode={postalCode}
                                    optionSelected={optionSelected}
                                    setOptionSelected={setOptionSelected}
                                    setDrawerState={setDrawerState}
                                  />
                                ))}
                              </>
                            )}
                          </div>

                          {/* Direcciones Guardadas */}
                          <div
                            className={`userAddressesListContainer ListDrawer${innerListsState.userAddresses}`}
                          >
                            {loadingStates.userAddresses === 'Fetching' ? (
                              <div
                                className="bigDrawer"
                                style={{ backgroundColor: 'rgba(59, 142, 226, 0.3)' }}
                              >
                                <LoadingOutlined style={{ marginRight: '10px' }} />
                                <p>{'Cargando Direcciones'}</p>
                              </div>
                            ) : (
                              <>
                                <div
                                  className={'bigDrawer ReadyToOpen'}
                                  style={{
                                    backgroundColor:
                                      fetchedDirections.optimizedData.length > 0
                                        ? 'rgba(111, 115, 246, 0.3)'
                                        : 'rgba(251, 150, 150, 0.5)'
                                  }}
                                  onClick={() => {
                                    if (fetchedDirections.optimizedData.length > 0) {
                                      setInnerListsState((prevListsState) => {
                                        return {
                                          ...prevListsState,
                                          userAddresses:
                                            prevListsState.userAddresses === 'Closed'
                                              ? 'Open'
                                              : 'Closed'
                                        }
                                      })
                                    }
                                  }}
                                >
                                  <UpCircleOutlined
                                    style={{
                                      marginRight: '5px',
                                      transition: 'transform 350ms',
                                      transform:
                                        innerListsState.userAddresses === 'Open'
                                          ? 'rotate(180deg)'
                                          : 'rotate(0deg)'
                                    }}
                                  />
                                  <p>
                                    {fetchedDirections.optimizedData.length > 0
                                      ? 'Direcciones Guardadas'
                                      : 'No se encontraron Direcciones'}
                                  </p>
                                </div>
                                {fetchedDirections.optimizedData.map((direction, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className="smallDrawer"
                                      onClick={() => {
                                        setOptionSelected(direction.idValue)
                                        setDrawerState('Closed')
                                      }}
                                    >
                                      <p>{direction.selectValue}</p>
                                    </div>
                                  )
                                })}
                              </>
                            )}
                          </div>
                          {/* Usar Codigo Postal */}
                          <div className={'userAddressesListContainer'}>
                            <div
                              className="bigDrawer"
                              style={{
                                marginRight: '5px',
                                backgroundColor: 'rgba(150, 251, 162, 0.5)'
                              }}
                              onClick={() => {
                                setDrawerState('Closed')
                                setOnlyCp(true)
                              }}
                            >
                              <NumberOutlined />
                              <p>{'Usar CP Escrito'}</p>
                            </div>
                          </div>
                          {/* Favorite address */}
                          <div
                            className={`userAddressesListContainer ListDrawer${innerListsState.userAddresses}`}
                          >
                            {loadingStates.userFavoriteAddresses === 'Fetching' ? (
                              <div
                                className="bigDrawer"
                                style={{ backgroundColor: 'rgba(59, 142, 226, 0.3)' }}
                              >
                                <LoadingOutlined style={{ marginRight: '10px' }} />
                                <p>{'Cargando direcciones favoritas'}</p>
                              </div>
                            ) : (
                              <>
                                <div
                                  className={'bigDrawer ReadyToOpen'}
                                  style={{
                                    backgroundColor:
                                      fetchedFavoriteAddresses.optimizedData.length > 0
                                        ? 'rgba(111, 115, 246, 0.3)'
                                        : 'rgba(251, 150, 150, 0.5)'
                                  }}
                                  onClick={() => {
                                    if (fetchedFavoriteAddresses.optimizedData.length > 0) {
                                      setInnerListsState((prevListsState) => {
                                        return {
                                          ...prevListsState,
                                          userAddresses:
                                            prevListsState.userAddresses === 'Closed'
                                              ? 'Open'
                                              : 'Closed'
                                        }
                                      })
                                    }
                                  }}
                                >
                                  <UpCircleOutlined
                                    style={{
                                      marginRight: '5px',
                                      transition: 'transform 350ms',
                                      transform:
                                        innerListsState.userAddresses === 'Open'
                                          ? 'rotate(180deg)'
                                          : 'rotate(0deg)'
                                    }}
                                  />
                                  <p>
                                    {fetchedFavoriteAddresses.optimizedData.length > 0
                                      ? 'Direcciones Favoritas'
                                      : 'No se encontraron direcciones favoritas'}
                                  </p>
                                </div>
                                {fetchedFavoriteAddresses.optimizedData.map((direction, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className="smallDrawer"
                                      onClick={() => {
                                        setOptionSelected(direction.idValue)
                                        setDrawerState('Closed')
                                      }}
                                    >
                                      <p>{direction.selectValue}</p>
                                    </div>
                                  )
                                })}
                              </>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        )
      case 'allCountriesStatesInputs':
      case 'localCountriesStatesInputs':
        return (
          <Row gutter={[10, 0]}>
            <Col flex="1 1 100px">
              <Form.Item
                name={`${inputProps.valueContainerName}SelectedCountry`}
                rules={[
                  {
                    required: inputProps.required,
                    message: inputProps.errorMessage
                      ? inputProps.errorMessage
                      : 'campo incompleto'
                  }
                ]}
              >
                <Select
                  showSearch
                  dropdownMatchSelectWidth={false}
                  style={{
                    fontWeight: 'bolder',
                    ...inputProps.style
                  }}
                  onChange={(data) => {
                    setCountrySelected(data)
                    inputProps.onCountryChange && inputProps.onCountryChange(data)
                  }}
                  placeholder={'Selecciona Un País'}
                  disabled={inputProps.disabled}
                  filterOption={(input, option) =>
                    // @ts-ignore
                    // (option!.children[1] as unknown as string)
                    (option.children[1])
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                >
                  {inputProps.type === 'localCountriesStatesInputs'
                    ? MapableCountriesSettings.map(([key, country]) => {
                        return (
                          <Option key={key} value={key}>
                            <img
                              src={country.SvgFlag}
                              alt={'d'}
                              style={{
                                width: '15px',
                                height: '15px',
                                marginRight: '5px',
                                marginBottom: '2.5px'
                              }}
                            />
                            {country.Name}
                          </Option>
                        )
                      })
                    : allCountries.map((country) => {
                        return (
                          <Option key={country.value} value={country.value}>
                            <span role="img">
                              <img
                                src={country.svgFlag}
                                alt={'d'}
                                style={{
                                  width: '15px',
                                  height: '15px',
                                  marginRight: '5px',
                                  marginBottom: '2.5px'
                                }}
                              />
                            </span>
                            {country.label}
                          </Option>
                        )
                      })}
                </Select>
              </Form.Item>
            </Col>
            <Col flex="1 1 100px">
              <Form.Item
                name={`${inputProps.valueContainerName ? inputProps.valueContainerName : ''}State`}
                rules={[
                  {
                    required: inputProps.required,
                    message: inputProps.errorMessage
                      ? inputProps.errorMessage
                      : 'campo incompleto'
                  }
                ]}
              >
                <Select
                  showSearch
                  style={{
                    fontWeight: 'bolder',
                    ...inputProps.style
                  }}
                  placeholder={'Selecciona un Estado'}
                  disabled={
                    inputProps.disabledState !== undefined
                      ? inputProps.disabledState
                      : inputProps.disabled
                  }
                  filterOption={(input, option) =>
                    // (option!.children as unknown as string)
                    (option.children)
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                >
                  {fetchedStates.map((state) => {
                    return (
                      <Option
                        key={state.name}
                        value={
                          state?.code_2_digits !== null
                            ? state?.code_2_digits
                            : state?.code_3_digits?.substring(0, 2)
                        }
                      >
                        {state.name}
                      </Option>
                    )
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        )
      default:
        return null
    }
  }

  return <>{getComponent()}</>
}

export default InputCountriesContainer
