// Imports
import React, { createContext, useState } from 'react'

const initialSelectedCountry = 'MX'
const initialSelectedData = {
    Name: 'México',
    SvgFlag: 'MexicoSvg',
    Currency: 'mxn',
    Language: 'es',
    PhoneExtension: '+52',
    PostalCodesLength: 4,
    AllowedParcels: [
        'estafeta',
        'mensajerosUrbanos',
        'noventa9Minutos',
        'quiken',
        'fedex',
        'dhl',
        'redpack',
        'carssa',
        'scm',
        'sendex',
        'ampm',
        'ivoy',
        'paquetexpress',
        'ups',
        'jtexpress',
        'borzo',
        'treggo',
        'uber',
        'pickit'
    ],
    StoreTabImages: [],
    TopUpRechargeComponents: []
    // FormExtraFields: InputProps[],
}

export const CountriesContext = createContext({
  initialSelectedCountry,
  initialSelectedData
})

export const CountriesContextProvider = (props) => {
  const [currentSelectedCountry, setCurrentSelectedCountry] = useState(initialSelectedCountry)
  const [currentCountrySelectedData, setCurrentCountrySelectedData] = useState(initialSelectedData)

  const formatPrice = (price) => {
    return Intl.NumberFormat(
      `${currentCountrySelectedData?.Language}-${currentSelectedCountry}`
    ).format(price)
  }

  return (
    <CountriesContext.Provider
      value={{
        formatPrice,
        currentSelectedCountry,
        currentCountrySelectedData,
        setCurrentSelectedCountry,
        setCurrentCountrySelectedData
      }}
    >
      {props.children}
    </CountriesContext.Provider>
  )
}
