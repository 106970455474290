import axios from 'axios'
import React, { useContext, useState, useEffect, useMemo } from 'react'
import { Row, Col, Form, Button, Tag, notification, Tooltip, Typography } from 'antd'
import anime from 'animejs'

import { QuoteDataContext } from '../../../context/QuoteDataContext'
import { DataContext } from '../../../context/dataContext'
import { CountriesContext } from '../../../context/CountriesContext'

// import useDevice from '../../../utils/hooks/useDevice'
// import { useGet } from '../../../hooks/useGet'
// import { getUserPackages } from '../../../services/packages_s'
// import { TPackagesResponse } from '../../../interfaces/packages.interfaces'
// import { useTranslation } from 'react-i18next'

import LottieContainer from '../containers/LottieContainer'
import CardContainer from '../containers/CardContainer'
import InputContainer from '../containers/InputContainer'
import InputCountriesContainer from '../containers/InputCountriesContainer'

import { FillMultipackageForm } from '../form/FillMultipackageForm'

// Lotties
import NationalLottie from '../lotties/delivery.json'
import InternationalLottie from '../lotties/purpleWorld.json'
import PalletLottie from '../lotties/palletTruck.json'
import ContraEntregaLottie from '../lotties/contraEntrega.json'
import LockLottie from '../lotties/lock.json'
import ListLottie from '../lotties/purpleList.json'
import handMoney from '../lotties/handMoney.json'

import { DeleteOutlined, InfoCircleOutlined, PlusCircleOutlined } from '@ant-design/icons'

//tour
// import useTour from '../../../utils/Tour/useTour'
// import { STEPSCOTIZAR } from '../../../constants/tourStep'

//Response Parcels
// const STEPS = STEPSCOTIZAR

// const defaultOriginPC = {
//   postalCode: "64720",
//   entireString: "64720 ~ Independencia ~ Monterrey (Nuevo León)",
//   addressType: "FetchedAddress",
//   entireObject: {
//     zip_code: "64720",
//     country: {
//       name: "México",
//       code: "MX"
//     },
//     state: {
//       name: "Nuevo León",
//       iso_code: "MX-NLE",
//       code: {
//         "1digit": null,
//         "2digit": "NL",
//         "3digit": "NLE"
//       }
//     },
//     locality: "Monterrey",
//     additional_info: {
//       street: null
//     },
//     suburbs: [
//       "Alfonso Reyes",
//       "Ancira",
//       "Benito Juárez (F-96)",
//       "Centro",
//       "Contry",
//       "Independencia",
//       "Loma Larga",
//       "Monterrey Centro",
//       "Nuevo Repueblo",
//       "Obispado",
//       "Roma",
//       "Unión Miguel Barrera",
//       "Unión de Colonos A Reyes",
//       "Victoria"
//     ],
//     coordinates: {
//       latitude: "25.652378",
//       longitude: "-100.307463"
//     },
//     info: {
//       stat: "194358",
//       stat_8digit: "19435800",
//       time_zone: "America/Monterrey",
//       utc: "-06:00"
//     },
//     regions: {
//       region_1: "Nuevo León",
//       region_2: "Monterrey",
//       region_3: "",
//       region_4: ""
//     }
//   },
//   onSelected: "OriginPC"
// }

const defaultOriginPC = {
  "postalCode": "66478",
  "entireString": "66478 ~ Los Angeles 3er Sector ~ San Nicolás de los Garza (Nuevo León)",
  "addressType": "FetchedAddress",
  "entireObject": {
      "zip_code": "66478",
      "country": {
          "name": "México",
          "code": "MX"
      },
      "state": {
          "name": "Nuevo León",
          "iso_code": "MX-NLE",
          "code": {
              "1digit": null,
              "2digit": "NL",
              "3digit": "NLE"
          }
      },
      "locality": "San Nicolás de Los Garza",
      "additional_info": {
          "street": null
      },
      "suburbs": [
          "Arboledas de San Cristóbal",
          "Casa Blanca San Ángel",
          "Cerradas de Casa Blanca",
          "Fresnos del Lago",
          "Fresnos del Lago 1er Sector",
          "Fresnos del Lago 2.o Sector",
          "Fresnos del Lago 3er Sector",
          "Los Angeles 1er Sector",
          "Los Angeles 3er Sector",
          "Los Angeles 4.o Sector",
          "Los Angeles 5.o Sector",
          "Los Pinos",
          "Los Ángeles Sector 3",
          "Los Ángeles Sector 4",
          "Los Ángeles Sector 5",
          "Mision Casa Blanca 1er Sector",
          "Mision Casa Blanca 2.o Sector",
          "Misiones de San Cristóbal",
          "Misión de Casa Blanca",
          "Misión de San Cristóbal",
          "Palmas Diamante",
          "Privadas de Casa Blanca",
          "Quinta Montecarlo",
          "Residencial San Cristobal 1er Sector",
          "Residencial San Cristobal 2.o Sector",
          "Residencial San Cristobal 3er Sector",
          "Residencial San Cristóbal",
          "Residencial San Cristóbal Sector Tres",
          "Residencial los Angeles Sect 1",
          "Residencial los Angeles Sect 2",
          "Rincon de Casa Blanca 2.o Sector",
          "Rincón de Casa Blanca 1er Sector",
          "Rincón de Casa Blanca Sector 2",
          "Rincón de los Ángeles",
          "San Cristóbal",
          "Sin Nombre de Colonia 18",
          "Sin Nombre de Colonia 34",
          "Valle Casa Blanca",
          "Valle de Casa Blanca",
          "Valle de Casa Blanca 1er Sector",
          "Villas de San Cristobal 1er Sector",
          "Villas de San Cristobal 2.o Sector",
          "Villas de San Cristóbal"
      ],
      "coordinates": {
          "latitude": "25.736000",
          "longitude": "-100.236258"
      },
      "info": {
          "stat": "193685",
          "stat_8digit": "19368500",
          "time_zone": "America/Monterrey",
          "utc": "-06:00"
      },
      "regions": {
          "region_1": "Nuevo León",
          "region_2": "San Nicolás de los Garza",
          "region_3": "",
          "region_4": ""
      }
  },
  "onSelected": "OriginPC"
}

const insuranceValidation = (value, country) => {
  switch (country) {
    case 'MX':
      if (value > 10000) return true
      return false
    case 'CO':
      if (value > 2350000) return true
      return false
    default:
      return false
  }
}

const PostalCodeQuote = ({ token, integrationOrder, initAddressData, destinationData }) => {
  // const { isMobile } = useDevice()
  const { Text } = Typography
  const [outboundData, setOutboundData] = useState(undefined)

  const IntegrationCompletePC = useMemo(() => {
    return `${integrationOrder?.shipping_address?.zip_code} ~ ${integrationOrder?.customer_details.name.first_name} ~ ${integrationOrder?.shipping_address.address}`
  }, [integrationOrder])

  const IntegrationCompleteOriginPC = useMemo(() => {
    return `${integrationOrder?.origin_address?.zip_code} ~ ${integrationOrder?.merchant_details?.name.first_name} ~ ${integrationOrder?.origin_address?.address}`
  }, [integrationOrder])

  const AddressCompleteOriginPC = useMemo(() => {
    return `${initAddressData?.Destiny.postal_code} ~ ${initAddressData?.Destiny.name} ~ ${initAddressData?.Destiny.city} ~ ${initAddressData?.Destiny.state}`
  }, [initAddressData])

  const AddressCompleteDestinyPC = useMemo(() => {
    return `${initAddressData?.Origin.postal_code} ~ ${initAddressData?.Origin.name} ~ ${initAddressData?.Origin.city} ~ ${initAddressData?.Origin.state}`
  }, [initAddressData])

  const {
    quoteData,
    setQuoteStepTransition,
    shipmentTypeSelected,
    setShipmentTypeSelected,
    setQuoteData
  } = useContext(QuoteDataContext)

  const [packagesSaved, setPackagesSaved] = useState([])
  const { currentCountrySelectedData, currentSelectedCountry } = useContext(CountriesContext)
  const { data: { userInfo: userData } } = useContext(DataContext)
  const [selectedContainer, setSelectedContainer] = useState('box')
  const [volumetricWeight, setVolumetricWeight] = useState(0)
  const [savedPackageSelected, setSavedPackageSelected] = useState(false)
  const [insuranceActive, setInsuranceActive] = useState(false)
  const [contraEntregaActive, setContraEntregaActive] = useState(false)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [initialState, setInitialState] = useState(true)
  const [isInternational, setIsInternational] = useState(false)
  const [storeExtraData, setStoreExtraData] = useState({
    district: '',
    state: '',
    country: ''
  })

  const [savedAddress, setSavedAddress] = useState({
    destiny: {
      postalCode: '',
      entireString: '',
      addressType: 'SavedAddress',
      entireObject: {},
      onSelected: undefined
    },
    origin: defaultOriginPC
  })

  console.log("savedAddress => ", savedAddress)

  const [multipaq, setMultipaq] = useState([])
  const [form] = Form.useForm()
  const onFinish = (values) => {
    let OriginData = {
      PostalCodeComplete: integrationOrder
        ? IntegrationCompleteOriginPC
        : savedAddress.origin.entireString,
      country: values.OriginPCSelectedCountry,
      postal_code: savedAddress?.origin.postalCode
    }
    let DestinyData = {
      PostalCodeComplete: integrationOrder
        ? IntegrationCompletePC
        : savedAddress.destiny.entireString,
      country: values.DestinyPCSelectedCountry,
      postal_code: savedAddress?.destiny?.postalCode ?? outboundData?.entireString ?? savedAddress?.destiny.postalCode
    }

    // Revisar el tipo de Codigo Postal Seleccionado y poner Información de Origen Específica
    if (savedAddress?.origin.addressType === 'FetchedAddress') {
      OriginData = {
        ...OriginData,
        // Si es de Mexico ocupa ciudad normal, si es de colocmbia la ocupa en 8 digitos
        city: savedAddress.origin.entireObject.locality,
        state: savedAddress.origin.entireObject.state.code['2digit'],
        district: savedAddress.origin.entireString?.split('~')[1].trim()
      }
    } else if (savedAddress?.origin.addressType === 'SavedAddress') {
      const {
        name,
        email,
        phone,
        company,
        int_number,
        number,
        street,
        reference,
        city,
        state,
        district
      } = savedAddress.origin.entireObject

      OriginData = {
        ...OriginData,
        name,
        email,
        phone,
        company,
        int_number,
        number,
        street,
        reference,
        city,
        state,
        district
      }
    } else if (savedAddress?.origin.addressType === 'StoreAddress') {
      OriginData = {
        ...OriginData,
        name: savedAddress.origin.entireObject?.merchant_details?.name.full_name,
        email: savedAddress.origin.entireObject?.merchant_details?.email,
        phone: savedAddress.origin.entireObject?.merchant_details?.phone,
        company: savedAddress.origin.entireObject?.merchant_details?.company,
        street: savedAddress.origin.entireObject?.origin_address?.address,
        reference: savedAddress.origin.entireObject?.origin_address?.reference,
        city: savedAddress.origin.entireObject?.origin_address?.city,
        state:
          storeExtraData.state !== ''
            ? storeExtraData.state
            : savedAddress.origin.entireObject?.origin_address?.province.code.substring(0, 2),
        district:
          storeExtraData.district !== ''
            ? savedAddress.origin.entireObject?.origin_address?.neighborhood
            : storeExtraData.district
      }
    }

    // Revisar el Codigo Postal Seleccionado y poner Información de Destino Específica
    if (savedAddress?.destiny.addressType === 'FetchedAddress') {
      DestinyData = {
        ...DestinyData,
        // Si es de Mexico ocupa ciudad normal, si es de colocmbia la ocupa en 8 digitos
        city: savedAddress.destiny.entireObject.locality,
        state: savedAddress.destiny.entireObject.state.code['2digit'],
        district: savedAddress.destiny.entireString?.split('~')[1].trim()
      }
    } else if (savedAddress?.destiny.addressType === 'SavedAddress') {
      const {
        name,
        email,
        phone,
        company,
        int_number,
        number,
        street,
        reference,
        city,
        state,
        district
      } = savedAddress.destiny.entireObject

      DestinyData = {
        ...DestinyData,
        name,
        email,
        phone,
        company,
        int_number,
        number,
        street,
        reference,
        city,
        state,
        district
      }
    } else if (savedAddress?.destiny.addressType === 'StoreAddress') {
      DestinyData = {
        ...DestinyData,
        name: savedAddress.destiny.entireObject.customer_details.name.full_name,
        email: savedAddress.destiny.entireObject.customer_details.email,
        phone: savedAddress.destiny.entireObject.customer_details.phone,
        company: savedAddress.destiny.entireObject.customer_details.company,
        street: savedAddress.destiny.entireObject.shipping_address.address,
        reference: savedAddress.destiny.entireObject.shipping_address.reference,
        city: savedAddress.destiny.entireObject.shipping_address.city,
        state:
          storeExtraData.state !== ''
            ? storeExtraData.state
            : savedAddress.destiny.entireObject.shipping_address.province.code.substring(0, 2),
        district:
          storeExtraData.district !== ''
            ? savedAddress.destiny.entireObject.shipping_address.neighborhood
            : storeExtraData.district
      }
    }

    if (insuranceValidation(values.Insurance, values.OriginPCSelectedCountry))
      return notification.info({
        message: 'Valor del seguro inválido'
      })

    const fetchedInputQuoteData = {
      ...quoteData,
      AddressData: {
        OriginData,
        DestinyData
      },
      PackageData: {
        Height: values.Height,
        Length: values.Length,
        Weight: values.Weight,
        Width: values.Width,
        Container: selectedContainer,
        Insurance: values.Insurance,
        ShippingType: shipmentTypeSelected,
        ContraEntrega: values.contraEntrega
      }
    }

    setQuoteData(fetchedInputQuoteData)
    setQuoteStepTransition('2) Select your favorite parcel')
  }

  const onFinishFailed = (errorInfo) => {
    console.log(errorInfo)
  }

  const deletePackagesArray = () => {
    setQuoteData({
      ...quoteData,
      PackageArray: []
    })
  }

  const onSave = (e) => {
    const mapKey = e.onSelected === 'OriginPC' ? 'origin' : 'destiny'

    if (integrationOrder && (mapKey === 'destiny' || mapKey === 'origin') && e.addressType === 'FetchedAddress') {
      setStoreExtraData({
        district: e.entireObject.suburbs[1] || '',
        state: e.entireObject.state.code['2digit'] ,
        country: e.entireObject.country.code
      })
      return
    } else if (initAddressData) {
      return
    } else {
      setSavedAddress({
        ...savedAddress,
        [mapKey]: {
          ...e
        }
      })
    }
  }
  const SwitchShipmentType = () => {
    setTimeout(() => {
      //Este if lo coloco, para que animación no se trunque.
      if (contraEntregaActive) {
        TransitionShipmentType('ContraEntrega')
        return
      }
      if (selectedContainer === 'pallet') {
        TransitionShipmentType('Pallet')
        CheckVolumetricWeight()
        return
      }
      if (
        form.getFieldValue('OriginPCSelectedCountry') ===
        form.getFieldValue('DestinyPCSelectedCountry')
      )
        TransitionShipmentType('National')
      if (
        form.getFieldValue('OriginPCSelectedCountry') !==
        form.getFieldValue('DestinyPCSelectedCountry') &&
        form.getFieldValue('OriginPCSelectedCountry') === currentSelectedCountry
      ) {
        TransitionShipmentType('International')
      }
      if (
        form.getFieldValue('OriginPCSelectedCountry') !== currentSelectedCountry &&
        form.getFieldValue('DestinyPCSelectedCountry') === currentSelectedCountry
      ) {
        TransitionShipmentType('Import')
      }
      CheckVolumetricWeight()
    }, 250)
  }

  const CheckVolumetricWeight = () => {
    setTimeout(() => {
      const Width = form.getFieldValue('Width')
      const Length = form.getFieldValue('Length')
      const Height = form.getFieldValue('Height')

      const VolumetricWeight = Number(((Width * Length * Height) / 5000).toFixed(2))
      const result = isNaN(VolumetricWeight) ? 0 : VolumetricWeight
      setVolumetricWeight(result)

      if (selectedContainer === 'pallet') {
        TransitionShipmentType('Pallet')
      }
    }, 250)
  }

  const TransitionShipmentType = (type) => {
    if (type === shipmentTypeSelected) {
    } else {
      setTimeout(() => {
        setShipmentTypeSelected(type)
      }, 666)
      anime({
        targets: '#leftPostalQuoteContainer',
        opacity: [1, 0, 1],
        easing: 'linear',
        duration: 1000
      })
    }
  }

  useEffect(() => {
    if (!initialState) {
      SwitchShipmentType()
    }
  }, [selectedContainer, contraEntregaActive]) //eslint-disable-line

  useEffect(() => {
    if (integrationOrder) {
      setSavedAddress({
        ...savedAddress,
        // origin: {
        //   postalCode: integrationOrder.origin_address?.zip_code ?? '',
        //   entireString: IntegrationCompletePC,
        //   addressType: 'StoreAddress',
        //   entireObject: integrationOrder,
        //   onSelected: 'OriginPC'
        // },
        destiny: {
          postalCode: integrationOrder.shipping_address?.zip_code,
          entireString: IntegrationCompletePC,
          addressType: 'StoreAddress',
          entireObject: integrationOrder,
          onSelected: 'DestinyPC'
        }
      })
    } else if (initAddressData) {
      setSavedAddress({
        destiny: {
          addressType: 'SavedAddress',
          postalCode: initAddressData.Destiny.postal_code
            ? initAddressData.Destiny.postal_code
            : destinationData?.postal_code ?? "",
          entireObject: initAddressData.Destiny,
          entireString: `${initAddressData?.Destiny.postal_code} ~ ${initAddressData?.Destiny.name} ~ ${initAddressData?.Destiny.city} ~ ${initAddressData?.Destiny.state}`
        },
        origin: {
          addressType: 'SavedAddress',
          postalCode: initAddressData.Origin.postal_code ? initAddressData.Origin.postal_code : '',
          entireObject: initAddressData.Origin,
          entireString: `${initAddressData?.Origin.postal_code} ~ ${initAddressData?.Origin.name} ~ ${initAddressData?.Origin.city} ~ ${initAddressData?.Origin.state}`
        }
      })
    }

    TransitionShipmentType('awaiting...')

    setInitialState(false)
  }, []) //eslint-disable-line

  useEffect(() => {
    form.setFieldsValue({
      // Height: integrationOrder ? Number(integrationOrder.product_items[0].measures?.height) : quoteData.PackageArray[0]?.Height,
      // Length: integrationOrder ? Number(integrationOrder.product_items[0].measures?.lenght) : quoteData.PackageArray[0]?.Length,
      // Weight: integrationOrder ? Number(integrationOrder.product_items[0].measures?.weight) : quoteData.PackageArray[0]?.Weight,
      // Width: integrationOrder ? Number(integrationOrder.product_items[0].measures?.width) : quoteData.PackageArray[0]?.Width,
      Height: quoteData.PackageArray[0]?.Height,
      Length: quoteData.PackageArray[0]?.Length,
      Weight: quoteData.PackageArray[0]?.Weight,
      Width: quoteData.PackageArray[0]?.Width,
      Container: 0
    })
    //eslint-disable-next-line
  }, [quoteData.PackageArray])

  useEffect(() => {

    if(destinationData?.postal_code){
      (async function getDestinyData(){
        try {
          const response = await axios.get(`https://geocodes.envia.com/zipcode/mx/${destinationData?.postal_code}`)
          if(response.data.length === 0){
            return
          }
          const { zip_code, state, locality } = response.data[0]
          const destinyData = {
            entireObject: {
              ...response.data[0],
            },
            postalCode: destinationData?.postal_code,
            entireString: `${destinationData?.postal_code} ~ ${state.name} ~ ${locality}`,
            addressType: "FetchedAddress",
            onSelected: "DestinyPC"
          }
          setOutboundData(destinyData)
          setSavedAddress({ ...savedAddress, destiny: destinyData })
          form.setFieldsValue({
            'DestinyPC': `${zip_code} ~ ${state.name} ~ ${locality}`
          })
          // console.log(destinyData)
        } catch (error) {
          // console.error(error)
          return error.response.data
        }
      }())
    }

    const getUserPackages = async ()=> {
        const { data } = await axios.get(
            `${process.env.REACT_APP_NOT_DRENVIO_BACKEND_URL}/users/packages/all`, 
            { headers: { Authorization: `Bearer ${token}` }}
        )

        setPackagesSaved(data)
    }

    getUserPackages()
    //eslint-disable-next-line
  }, [])
  
  // const tour = useTour(STEPS, 'LS_KEY28')
  // console.log('OriginPCSelectedCountry', form.getFieldValue('OriginPCSelectedCountry'))
  // console.log('DestinyPCSelectedCountry', form.getFieldValue('DestinyPCSelectedCountry'))
  // console.log('shipmentTypeSelected', shipmentTypeSelected)

  return (
    <>
      <Row gutter={[40, 0]} style={{ height: '100%' }}>
        <Col
          md={24}
          lg={10}
          style={{
            display: 'grid',
            placeItems: 'center',
            overflow: 'hidden',
            height: '100%'
          }}
          className="responsiveHideSection"
        >
          <Row style={{ height: '100%' }} id="leftPostalQuoteContainer">
            <Col
              span={24}
              style={{
                display: 'grid',
                placeItems: 'center',
                height: '20%'
              }}
            >
              <CardContainer
                subtitle={
                  shipmentTypeSelected === 'Import'
                    ? 'Importacion'
                    : shipmentTypeSelected === 'International'
                      ? 'Envío Internacional'
                      : shipmentTypeSelected === 'National'
                        ? 'Envío Nacional'
                        : shipmentTypeSelected === 'Pallet'
                          ? 'Envío en Tarima'
                          : shipmentTypeSelected === 'ContraEntrega'
                            ? 'Envío Contra Entrega'
                            : 'Cotiza tu Envío'
                }
                itemsOrder={['subtitle', 'title']}
                title={
                  shipmentTypeSelected === 'International'
                    ? 'Hacia todo el mundo'
                    : shipmentTypeSelected === 'National'
                      ? 'Paquetes de 0kg a 68kg'
                      : shipmentTypeSelected === 'Pallet'
                        ? 'Paquetes de más de 39kg'
                        : shipmentTypeSelected === 'ContraEntrega'
                          ? 'Pago de paquetes en efectivo'
                          : 'Introduce la información requerida'
                }
                subtitleStyle={{
                  display: 'grid',
                  placeItems: 'center',
                  textAlign: 'center',
                  color: '#2d1b6e',
                  fontSize: '1.5rem'
                }}
                titleStyle={{
                  textAlign: 'center',
                  display: 'grid',
                  placeItems: 'center'
                }}
                containerStyle={{ width: '80%' }}
              />
            </Col>
            <Col span={24} style={{ height: '55%', maxHeight: '400px' }}>
              <LottieContainer
                lottieOptions={{ loop: true, autoplay: true }}
                lottieSelected={
                  shipmentTypeSelected === 'National'
                    ? NationalLottie
                    : shipmentTypeSelected === 'International'
                      ? InternationalLottie
                      : shipmentTypeSelected === 'Pallet'
                        ? PalletLottie
                        : shipmentTypeSelected === 'Import'
                          ? InternationalLottie
                          : shipmentTypeSelected === 'ContraEntrega'
                            ? ContraEntregaLottie
                            : ListLottie
                }
                lottieStyle={{
                  transform:
                    shipmentTypeSelected === 'International' ||
                      shipmentTypeSelected === 'ContraEntrega'
                      ? 'scale(1.8)'
                      : 'scale(1)'
                }}
              />
            </Col>
          </Row>
        </Col>
        <Col
          md={24}
          lg={14}
          style={{
            height: '100%',
            overflowY: 'scroll'
          }}
        >
          <Form
            form={form}
            name="QuoteForm"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            initialValues={{
              Container: 0
            }}
          >
            <Row gutter={[0, 8]}>
              <Col span={24}>
                <CardContainer>
                  <Row gutter={[10, 10]}>
                    {/* Origin Card */}
                    <Col xs={24} sm={24} md={24} lg={24} style={{ zIndex: 3, display: 'none' }}>
                      <div className="origin">
                        {' '}
                        {/* {tour} */}
                        <p style={{ fontWeight: 'bold', marginTop: '5px', marginBottom: '5px' }}>
                          {'Origen'}
                        </p>
                        <InputCountriesContainer //Este es para seleccionar el PAIS de ORIGEN y CP
                          token={token}
                          textPlaceHolder={'Buscar una dirección'}
                          type="localCountriesPostalCodesInputs"
                          showUserAddresses
                          userFormHook={form}
                          required
                          valueContainerName="OriginPC"
                          onCountryChange={() => {
                            SwitchShipmentType()
                          }}
                          onPostalCodeChange={(e) => {
                            SwitchShipmentType()
                            onSave(e)
                          }}
                          initialPostalCode={integrationOrder
                            ? (initAddressData ? AddressCompleteOriginPC : undefined)
                            : initAddressData
                              ? AddressCompleteOriginPC : undefined}
                        />
                      </div>
                    </Col>

                    {/* Destiny Card */}
                    <Col xs={24} sm={24} md={24} lg={24} style={{ zIndex: 2 }}>
                      <div className="destination">
                        <p style={{ fontWeight: 'bold', marginTop: '5px', marginBottom: '5px' }}>
                          {'Destino'}
                        </p>
                        <InputCountriesContainer //Este es para seleccionar el PAIS de DESTINO y CP
                          token={token}
                          textPlaceHolder={'Buscar una dirección'}
                          type="allCountriesPostalCodeInputs"
                          showUserAddresses
                          userFormHook={form}
                          required
                          disabled={false}
                          valueContainerName="DestinyPC"
                          onCountryChange={() => {
                            SwitchShipmentType()
                            setIsInternational(true)
                          }}
                          onPostalCodeChange={(e) => {
                            SwitchShipmentType()
                            onSave(e)
                          }}
                          initialPostalCode={
                            integrationOrder
                              ? IntegrationCompletePC
                              : initAddressData
                                ? AddressCompleteDestinyPC
                                : undefined
                          }
                        />
                      </div>
                    </Col>
                  </Row>
                </CardContainer>
              </Col>

              {/* Package Data Card */}
              <Col span={24} style={{ zIndex: 1 }}>
                {quoteData.PackageArray.length > 1 ? (
                  <>
                    <CardContainer
                      cardStyle={{
                        height: quoteData.PackageArray.length > 2 ? '400px' : '300px',
                        overflow: 'scroll',
                        padding: '0 0 8px 0',
                        borderBottom: '1rem solid white',
                        marginRight: '10px'
                      }}
                    >
                      <div className="boton">
                        <CardContainer
                          title={'Paquetes a Cotizar'}
                          cardStyle={{
                            borderColor: 'transparent',
                            backgroundColor: 'transparent',
                            boxShadow: 'none'
                          }}
                          textList={quoteData.PackageArray.map((el) => (
                            <Row>
                              <Col span={24}>
                                <p>{`${el?.Container === 'envelope'
                                  ? 'Sobre'
                                  : 'Caja'
                                  }`}</p>
                                <Tag color="default">
                                  {`${el?.Insurance !== undefined
                                    ? 'Asegurado'
                                    : 'No asegurado'
                                    }`}
                                </Tag>
                                {el.Container !== 'envelope' && (
                                  <>
                                    <Tag color="default">
                                      {'Medidas'}: {el.Width} x {el.Height}{' '}
                                      x {el.Length} cm
                                    </Tag>
                                    <Tag color="default">
                                      {'Peso'}: {el.Weight} kg
                                    </Tag>
                                  </>
                                )}
                              </Col>
                            </Row>
                          ))}
                          textListDecoration="numbers"
                        >
                          <Button
                            style={{ display: 'flex', alignItems: 'center', marginTop: '12px' }}
                            icon={<DeleteOutlined />}
                            danger
                            onClick={deletePackagesArray}
                          >
                            {'Eliminar paquetes'}
                          </Button>
                        </CardContainer>
                      </div>
                    </CardContainer>
                  </>
                ) : (
                  <CardContainer
                    title={'Paquete'}
                    itemsSpacing={[0, 10]}
                    cardStyle={{ marginRight: '10px' }}
                  >
                    <div className="multiple">
                      <InputContainer
                        style={{
                          position: 'absolute',
                          top: '-35px',
                          right: '0px'
                        }}
                        shape="circle"
                        type="iconTitleHoverButton"
                        iconOrReactElement={<PlusCircleOutlined />}
                        title={'Cotizar multipaquete'}
                        onClick={() => setModalIsOpen(!modalIsOpen)}
                      />

                      <Row gutter={[5, 0]}>
                        <Col span={24}>
                          <div className="packages">
                            <InputContainer
                              type="multipleRadio"
                              valueContainerName="Container"
                              required
                              optionsList={[
                                'Caja',
                                'Sobre',
                                'Tarima'
                              ]}
                              onChange={(e) => {
                                if (e.target.value === 0) {
                                  setSelectedContainer('box')
                                  setSavedPackageSelected(false)
                                  form.setFieldsValue({
                                    selectedPackage: undefined,
                                    Height: undefined,
                                    Length: undefined,
                                    Width: undefined,
                                    Weight: undefined
                                  })
                                } else if (e.target.value === 1) {
                                  SwitchShipmentType()
                                  setSelectedContainer('envelope')
                                  form.setFieldsValue({
                                    Height: 10,
                                    Length: 10,
                                    Width: 10,
                                    Weight: 0.5
                                  })
                                } else if (e.target.value === 2) {
                                  SwitchShipmentType()
                                  setSelectedContainer('pallet')
                                  form.setFieldsValue({
                                    Height: 120,
                                    Length: 100,
                                    Width: 16.5,
                                    Weight: 61
                                  })
                                }
                              }}
                            />
                          </div>
                        </Col>

                        <Col span={24}>
                          <Row
                            style={{
                              transition: 'height 500ms',
                              overflow: 'hidden',
                              height: selectedContainer === 'box' ? '50px' : '0px'
                            }}
                          >
                            <Col span={24}>
                              <div className="savedPackages">
                                <InputContainer
                                  type="select"
                                  placeholder={'Mis paquetes guardados'}
                                  valueContainerName="selectedPackage"
                                  onClear={() => {
                                    setSavedPackageSelected(false)
                                    form.resetFields(['Height', 'Length', 'Width', 'Weight'])
                                  }}
                                  onChange={(e) => {
                                    const packageName = e?.split('[')[0]?.trim()
                                    const packageContent = e?.split('[')[1].replace(/\]/g, '')?.trim()
                                    packagesSaved?.forEach((packageSaved) => {
                                      if (
                                        packageSaved.name === packageName &&
                                        packageSaved.content === packageContent
                                      ) {
                                        setSavedPackageSelected(true)
                                        form.resetFields(['Height'])
                                        form.setFieldsValue({
                                          Height: packageSaved.height,
                                          Length: packageSaved.length,
                                          Width: packageSaved.width,
                                          Weight: packageSaved.weight
                                        })
                                        CheckVolumetricWeight()
                                      }
                                    })
                                  }}
                                  optionsList={
                                    packagesSaved
                                      ? packagesSaved?.map((packageSaved) => {
                                        return `${packageSaved.name} [ ${packageSaved.content} ]`
                                      })
                                      : []
                                  }
                                />
                              </div>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={12}>
                          <InputContainer
                            type="number"
                            suffix="cm"
                            onChange={CheckVolumetricWeight}
                            required
                            disabled={
                              selectedContainer === 'envelope' || savedPackageSelected
                                ? true
                                : false
                            }
                            valueContainerName="Height"
                            placeholder={`${'Alto'} `}
                          />
                        </Col>
                        <Col span={12}>
                          <InputContainer
                            type="number"
                            suffix="cm"
                            onChange={CheckVolumetricWeight}
                            required
                            disabled={
                              selectedContainer === 'envelope' || savedPackageSelected
                                ? true
                                : false
                            }
                            valueContainerName="Length"
                            placeholder={`${'Largo'} `}
                          />
                        </Col>
                        <Col span={12}>
                          <InputContainer
                            type="number"
                            suffix="cm"
                            onChange={(e) => {
                              CheckVolumetricWeight()
                            }}
                            required
                            disabled={
                              selectedContainer === 'envelope' || savedPackageSelected
                                ? true
                                : false
                            }
                            valueContainerName="Width"
                            placeholder={`${'Ancho'} `}
                          />
                        </Col>
                        <Col span={12}>
                          <InputContainer
                            type="number"
                            suffix="kg"
                            required
                            disabled={
                              selectedContainer === 'envelope' || savedPackageSelected
                                ? true
                                : false
                            }
                            valueContainerName="Weight"
                            placeholder={`${'Peso'} `}
                            onChange={CheckVolumetricWeight}
                          />
                        </Col>
                        <Col span={24}>
                          <span style={{ display: 'flex', alignContent: 'center' }}>
                            {'Peso volumétrico'}:
                            <strong>
                              {' '}
                              {<span> &nbsp; {volumetricWeight} Kg </span>}
                              <Tooltip title={'La guía se generá con el peso mayor entre el peso físico y el peso volumétrico'}>
                                <InfoCircleOutlined style={{ display: 'inline-flex' }} />
                              </Tooltip>
                            </strong>
                          </span>
                        </Col>
                      </Row>
                    </div>
                  </CardContainer>
                )}
              </Col>
              <Col span={24}>
                <CardContainer
                  itemsSpacing={[0, 10]}
                  cardStyle={{ marginRight: '10px' }}
                  title="Servicios adicionales"
                >
                  <div
                    className="ensure"
                    style={{
                      position: 'absolute',
                      top: '-35px',
                      right: '0px'
                    }}
                  >
                    <InputContainer
                      onClick={() => {
                        setInsuranceActive(!insuranceActive)
                      }}
                      title={'Asegurar Envío'}
                      type="iconTitleHoverButton"
                      shape={'circle'}
                      iconOrReactElement={
                        <LottieContainer
                          lottieStyle={{
                            position: 'absolute',
                            top: 0,
                            width: '100%',
                            height: '100%',
                            borderRadius: '50%'
                          }}
                          lottieOptions={{
                            loop: false,
                            autoplay: false
                          }}
                          lottieSelected={LockLottie}
                          lottieSpeed={insuranceActive ? 2 : 1}
                          lottieState={['playSegment', insuranceActive ? [25, 150] : [0, 25]]}
                          lottieTrigger={insuranceActive}
                        />
                      }
                    />
                  </div>

                  {currentSelectedCountry === 'CO' && (
                    <div
                      className="ensure"
                      style={{
                        position: 'absolute',
                        top: '-35px',
                        right: '50px'
                      }}
                    >
                      <InputContainer
                        onClick={() => {
                          setContraEntregaActive(!contraEntregaActive)
                        }}
                        title="Servicio Contra Entrega"
                        type="iconTitleHoverButton"
                        shape={'circle'}
                        iconOrReactElement={
                          <LottieContainer
                            lottieStyle={{
                              position: 'absolute',
                              top: 0,
                              width: '100%',
                              height: '100%',
                              borderRadius: '50%'
                            }}
                            lottieOptions={{
                              loop: false,
                              autoplay: false
                            }}
                            lottieSelected={handMoney}
                            lottieSpeed={contraEntregaActive ? 1 : 2}
                            lottieState={['playSegment', contraEntregaActive ? [30, 80] : [0, 80]]}
                            lottieTrigger={contraEntregaActive}
                          />
                        }
                      />
                    </div>
                  )}

                  <Row gutter={[5, 0]} style={{ marginTop: '10px' }}>
                    <Col span={24}>
                      <Row>
                        {!isInternational && userData?.app_country !== 'MX' && (
                          <Col
                            span={12}
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'flex-start',
                              alignItems: 'center'
                            }}
                          >
                            <InputContainer
                              valueContainerName="contraEntregaCheck"
                              type="checkbox"
                              style={{ margin: '5px', fontWeight: 500 }}
                              placeholder={<span>{'Envío Contra Entrega'}</span>}
                              checked={contraEntregaActive}
                              onChange={(e) => {
                                setContraEntregaActive(e.target.checked)
                              }}
                            />
                            <Tooltip title={'Crea un envío que se paga al momento de la entrega'}>
                              <InfoCircleOutlined style={{ color: '#6d71f6' }} />
                            </Tooltip>
                          </Col>
                        )}

                        {contraEntregaActive &&
                          !isInternational &&
                          currentSelectedCountry === 'CO' && (
                            <Col span={12}>
                              <InputContainer
                                type="number"
                                valueContainerName="contraEntrega"
                                placeholder={'Monto a cobrar'}
                                prefix="$"
                                suffix={`${currentCountrySelectedData?.Currency.toUpperCase()}`}
                                required={contraEntregaActive}
                              />
                            </Col>
                          )}
                      </Row>
                    </Col>
                    <Col span={24}>
                      <Row>
                        <Col
                          span={12}
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'center'
                          }}
                        >
                          <InputContainer
                            valueContainerName="insuranceCheck"
                            type="checkbox"
                            style={{ margin: '5px', fontWeight: 500 }}
                            placeholder={<Text>{'Asegurar Envío'}</Text>}
                            checked={insuranceActive}
                            onChange={(e) => {
                              setInsuranceActive(e.target.checked)
                            }}
                          />
                          <Tooltip title={'Asegura tu envío hasta por $10,000 MXN'}>
                            <InfoCircleOutlined style={{ color: '#6d71f6' }} />
                          </Tooltip>
                        </Col>
                        {insuranceActive && (
                          <Col span={12}>
                            <InputContainer
                              type="number"
                              valueContainerName="Insurance"
                              placeholder={'Monto a asegurar'}
                              prefix="$"
                              suffix={`${currentCountrySelectedData?.Currency.toUpperCase()}`}
                              required={insuranceActive}
                            />
                          </Col>
                        )}
                      </Row>
                    </Col>
                  </Row>

                  <InputContainer
                    onClick={() => form.submit()}
                    type="button"
                    title={'Cotizar'}
                    style={{ margin: '10px 0' }}
                  />
                </CardContainer>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <FillMultipackageForm
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        multipaq={multipaq}
        setMultipaq={setMultipaq}
        token={token}
      />
    </>
  )
}

export default PostalCodeQuote
