import React from "react";
import TopCards from "./dashboard/TopCards";
import ProductsOutOfStock from "./dashboard/ProductsOutOfStock";
import {Col, Row} from "antd";
import ProductsSelledCurrentMonth from "./dashboard/ProductsSelledCurrentMonth";

const Home = () => {

  return (
    <>
        <Row gutter={[10,10]}>
            <Col span={24}>
                <TopCards />
            </Col>
            <ProductsOutOfStock />
            <ProductsSelledCurrentMonth />
        </Row>
    </>
  );
};

export default Home;
