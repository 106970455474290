import React from 'react';
import Chart from 'react-apexcharts'

const CustomLineChart = ({ data, horizontal, serieName, type }) => {

    const dataChart = () => {
        const Data = data?.filter(
            (item) => item?._id !== null && item?._id !== "" && item._id !== undefined
        )
        return Data?.map((charts) => ({
            x: (charts._id).toUpperCase(),
            y: charts?.count,
        }))
    }

    const options = {
        chart: {
            stacked: true,
        },
        plotOptions: {
            bar: {
                horizontal: horizontal,
            },
        },
    }

    const series = [
        {
            data: dataChart(),
            name: serieName ?? "serie",
        },
    ]

    return (
        <div>
            <Chart options={options} series={series} type={type} width="100%" height="200%" />
        </div>
    );
};

export default CustomLineChart;