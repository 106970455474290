import React, { Fragment, useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Box,
  CardContent,
  TextField,
  Button,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Tooltip,
  TableHead,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth0 } from "../../hooks/react-auth0-spa";
import { useAlert } from "react-alert";
import { GetOutputInfo, ScanOutput } from "../../api/WarehouseOutputAPI";
import {Card} from "antd";

const useStyles = makeStyles((theme) => ({
  textField: {
    marginTop: theme.spacing(3),
  },
  textFieldSmall: {
    marginLeft: theme.spacing(2),
  },
}));

const envioInitialState = {
  EnvioId: "",
  Estatus: "",
  InfoEnvioProductos: [],
};

const WarehouseOutputScan = (props) => {
  const classes = useStyles();
  const { getTokenSilently } = useAuth0();
  const alert = useAlert();
  const [envio, setEnvio] = useState(envioInitialState);
  const [codigoProducto, setCodigoProducto] = useState("");

  async function fetchData() {
    const t = await getTokenSilently();
    const data = await GetOutputInfo(t, props.match.params.idEnvio);

    // console.log(data);
    setEnvio(data);
  }

  useEffect(() => {
    fetchData();
  }, []);//eslint-disable-line

  const handleCaptureSubmit = (e) => {
    e.preventDefault();
    const productoCapturado = envio.InfoEnvioProductos.find((obj) => {
      return obj.Producto.SKUFulfillment.slice(-12) === codigoProducto;
    });

    if (!productoCapturado){
      alert.error('Codigo de producto invalido')
      return 
    }

    setEnvio({
      ...envio,
      InfoEnvioProductos: [
        ...envio.InfoEnvioProductos.filter((obj) => {
          return obj.Producto.SKUFulfillment.slice(-12) !== codigoProducto;
        }),
        {
          ...productoCapturado,
          CantidadRecibida: productoCapturado.CantidadRecibida + 1,
        },
      ],
    });
    setCodigoProducto("");
  };

  const handleCaptureChange = (e) => {
    if (e.target.value.length <= 12) {
      setCodigoProducto(e.target.value);
    }
  };

  const handleTerminar = async (e) => {
    const finish = window.confirm("¿Seguro que desea terminar la captura?");
    if (finish) {
      const t = await getTokenSilently();
      await ScanOutput(t, envio, props.match.params.idEnvio);
      alert.success("El paquete fue capturado correctamente");
      props.history.push("/warehouseoutput");
    }
  };

  return (
    <Fragment>
      <Card>
        <CardContent>
          <Grid container>
            <Grid item sm={6}>
              <Typography variant="subtitle1" gutterBottom>
                Captura o escanea el código de los productos programados para
                envío por el cliente. Al finalizar oprime <b>Terminar</b> para
                confirmar la entrada de los productos.
              </Typography>
            </Grid>
          </Grid>

          <Box mt={3}>
            <form onSubmit={handleCaptureSubmit}>
              <Grid container>
                <Grid item xs={10}>
                  <TextField
                    name="CodigoProducto"
                    label="Código de producto"
                    variant="outlined"
                    fullWidth
                    value={codigoProducto}
                    required={true}
                    helperText="Captura o escanea el código un producto"
                    onChange={handleCaptureChange}
                    autoFocus
                  />
                </Grid>
                <Box ml={3}>
                  <Grid item xs={2}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.textField}
                    >
                      Capturar
                    </Button>
                  </Grid>
                </Box>
              </Grid>
            </form>
          </Box>

          <Grid container>
            <Grid item xs={12}>
              <Typography variant="subtitle1" gutterBottom>
                Productos registrados en el paquete por el cliente
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell colSpan={3}>
                          Información del producto
                        </TableCell>
                        <TableCell>Cantidad registrada</TableCell>
                        <TableCell>Cantidad capturada</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {envio.InfoEnvioProductos.map(
                        (
                          { Producto, CantidadEnviada, CantidadRecibida },
                          index
                        ) => (
                          <TableRow key={index} id={index}>
                            <TableCell width="100">
                              <img
                                src={Producto?.Fotografia ?? ""}
                                alt={Producto?.Nombre ?? ""}
                                width="100"
                                height="100"
                              />
                            </TableCell>
                            <TableCell>
                              <Grid container>
                                <Grid item xs={12}>
                                  <Typography
                                    variant="caption"
                                    display="block"
                                    gutterBottom
                                  >
                                    # {Producto?.SKUFulfillment?.slice(-12)}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <Typography variant="h6" gutterBottom>
                                    {Producto?.Nombre ?? ""}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <Typography variant="body2" gutterBottom>
                                    {Producto?.Descripcion ?? ""}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </TableCell>
                            <TableCell>
                              <Grid container>
                                <Grid item xs={12}>
                                  <Typography variant="body1" gutterBottom>
                                    SKU {Producto?.SKUCliente ?? ""}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <Typography variant="body1" gutterBottom>
                                    Peso {Producto?.PesoKG ?? ""} Kg.
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <Typography variant="body1" gutterBottom>
                                    Dimensiones {Producto?.Largo ?? ""} x{" "}
                                    {Producto?.Ancho ?? ""} x {Producto?.Altura ?? ""} cm.
                                  </Typography>
                                </Grid>
                              </Grid>
                            </TableCell>
                            <TableCell>{CantidadEnviada}</TableCell>
                            <TableCell>{CantidadRecibida}</TableCell>
                          </TableRow>
                        )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Grid>
            <Grid container justify="flex-end">
              <Tooltip title="Confirma la recepción de la mercancía del cliente">
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  className={classes.textField}
                  onClick={handleTerminar}
                >
                  Terminar
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Fragment>
  );
};

export default WarehouseOutputScan;
