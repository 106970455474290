import React, { Fragment, useState } from "react";
import {
  Grid,
  Typography,
  Box,
  CardContent,
  TextField,
  Button,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Tooltip,
  TableHead,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  GetShippmentByCode,
  FinishScanShippment,
} from "../../api/WarehouseShippingAPI";
import { useAuth0 } from "../../hooks/react-auth0-spa";
import { useAlert } from "react-alert";
import {Card} from "antd";

const useStyles = makeStyles((theme) => ({
  textField: {
    marginTop: theme.spacing(3),
  },
  textFieldSmall: {
    marginLeft: theme.spacing(2),
  },
}));

const envioInitialState = {
  EnvioId: "",
  Estatus: "",
  InfoEnvioProductos: [],
};
const WarehouseReceiveIndex = (props) => {
  const classes = useStyles();
  const [codigoEnvio, setCodigoEnvio] = useState("");
  const [codigoProducto, setCodigoProducto] = useState("");
  const [envio, setEnvio] = useState(envioInitialState);
  const { getTokenSilently } = useAuth0();
  const alert = useAlert();

  const handleSearchSubmit = async (e) => {
    e.preventDefault();
    const t = await getTokenSilently();
    const data = await GetShippmentByCode(t, codigoEnvio);

    if (data.code !== 200){
      alert.error("El codigo de envio ingresado no existe");
      return 
    }
    setEnvio(data.res);
  };

  const handleSearchChange = (e) => {
    if (e.target.value.length <= 12) {
      setCodigoEnvio(e.target.value);
    }
  };

  const cleanEnvioInfo = (e) => {
    setEnvio(envioInitialState);
    setCodigoEnvio("");
  };

  const handleCaptureSubmit = (e) => {
    e.preventDefault();
    const productoCapturado = envio.InfoEnvioProductos.find((obj) => {
      return obj.Producto.SKUFulfillment.slice(-12) === codigoProducto;
    });

    if (!productoCapturado){
      alert.error('Codigo de producto invalido')
      return 
    }

    setEnvio({
      ...envio,
      InfoEnvioProductos: [
        ...envio.InfoEnvioProductos.filter((obj) => {
          return obj.Producto.SKUFulfillment.slice(-12) !== codigoProducto;
        }),
        {
          ...productoCapturado,
          CantidadRecibida: productoCapturado.CantidadRecibida + 1,
        },
      ],
    });
    setCodigoProducto("");
  };

  const handleCaptureChange = (e) => {
    if (e.target.value.length <= 12) {
      setCodigoProducto(e.target.value);
    }
  };

  const handleTerminar = async (e) => {
    const finish = window.confirm("¿Seguro que desea terminar la captura?");
    if (finish) {
      const t = await getTokenSilently();
      await FinishScanShippment(t, envio);
      cleanEnvioInfo();
      alert.success("El paquete fue capturado correctamente");
    }
  };

  return (
    <Fragment>
      {envio.InfoEnvioProductos.length === 0 && (
        <Card>
          <CardContent>
            <form autoComplete="off" onSubmit={handleSearchSubmit}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    Escanea un paquete
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="CodigoEnvio"
                    label="Código de envío"
                    variant="outlined"
                    fullWidth
                    value={codigoEnvio}
                    required={true}
                    helperText="Captura o escanea el código de barras del paquete"
                    onChange={handleSearchChange}
                    autoFocus
                    autoComplete="false"
                  />
                </Grid>
                <Grid item xs={12} container justify="flex-end">
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.textField}
                  >
                    Buscar
                  </Button>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Card>
      )}
      {envio.InfoEnvioProductos.length > 0 && (
        <Fragment>
          <Card>
            <CardContent>
              <Grid container>
                <Grid item sm={6}>
                  <Typography variant="subtitle1" gutterBottom>
                    Captura o escanea el código de los productos contenidos en
                    el paquete para capturar contabilizar el contenido. Al
                    finalizar oprime <b>Terminar</b> para confirmar la entrada
                    de los productos.
                  </Typography>
                </Grid>
                <Grid item xs={6} container justify="flex-end">
                  <Tooltip title="Perder los cambios y escanear otro paquete">
                    <Button
                      type="button"
                      variant="outlined"
                      color="primary"
                      className={classes.textField}
                      onClick={cleanEnvioInfo}
                    >
                      Escanear otro paquete
                    </Button>
                  </Tooltip>
                </Grid>
              </Grid>

              <Box mt={3}>
                <form autoComplete="off" onSubmit={handleCaptureSubmit}>
                  <Grid container>
                    <Grid item xs={10}>
                      <TextField
                        name="CodigoProducto"
                        label="Código de producto"
                        variant="outlined"
                        fullWidth
                        value={codigoProducto}
                        required={true}
                        helperText="Captura o escanea el código un producto"
                        onChange={handleCaptureChange}
                        autoFocus
                        autoComplete={false}
                      />
                    </Grid>
                    <Box ml={3}>
                      <Grid item xs={2}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          className={classes.textField}
                        >
                          Capturar
                        </Button>
                      </Grid>
                    </Box>
                  </Grid>
                </form>
              </Box>

              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" gutterBottom>
                    Productos registrados en el paquete por el cliente
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Box>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell colSpan={3}>
                              Información del producto
                            </TableCell>
                            <TableCell>Cantidad registrada</TableCell>
                            <TableCell>Cantidad recibida</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {envio.InfoEnvioProductos.map(
                            (
                              { Producto, CantidadEnviada, CantidadRecibida },
                              index
                            ) => (
                              <TableRow key={index} id={index}>
                                <TableCell width="100">
                                  <img
                                    src={Producto?.Fotografia || ""}
                                    alt={Producto?.Nombre || ""}
                                    width="100"
                                    height="100"
                                  />
                                </TableCell>
                                <TableCell>
                                  <Grid container>
                                    <Grid item xs={12}>
                                      <Typography
                                        variant="caption"
                                        display="block"
                                        gutterBottom
                                      >
                                        # {Producto?.SKUFulfillment.slice(-12)}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                      <Typography variant="h6" gutterBottom>
                                        {Producto?.Nombre}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                      <Typography variant="body2" gutterBottom>
                                        {Producto?.Descripcion}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </TableCell>
                                <TableCell>
                                  <Grid container>
                                    <Grid item xs={12}>
                                      <Typography variant="body1" gutterBottom>
                                        SKU {Producto?.SKUCliente}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                      <Typography variant="body1" gutterBottom>
                                        Peso {Producto?.PesoKG} Kg.
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                      <Typography variant="body1" gutterBottom>
                                        Dimensiones {Producto?.Largo} x{" "}
                                        {Producto?.Ancho} x {Producto?.Altura} cm.
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </TableCell>
                                <TableCell>{CantidadEnviada}</TableCell>
                                <TableCell>{CantidadRecibida}</TableCell>
                              </TableRow>
                            )
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Grid>
                <Grid container justify="flex-end">
                  <Tooltip title="Confirma la recepción de la mercancía del cliente">
                    <Button
                      type="button"
                      variant="contained"
                      color="primary"
                      className={classes.textField}
                      onClick={handleTerminar}
                    >
                      Terminar
                    </Button>
                  </Tooltip>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Fragment>
      )}
    </Fragment>
  );
};

export default WarehouseReceiveIndex;
