import { format } from "date-fns"

export const productsDownloads = (data)=> {
    return data.map((item) => ({
        SKUCliente: item.SKUCliente,
        Nombre: item.Nombre,
        Descripcion: item.Descripcion,
        ValorMercancia: item.ValorMercancia,
        PesoKG: item.PesoKG,
        Largo: item.Largo,
        Ancho: item.Ancho,
        Altura: item.Altura,
        CantidadEnInventario: item.CantidadEnInventario,
    })) 
}

export const warehouseShipmentsDownloads = (data) => {
    return data.map((item) => ({
        CodigoEnvio: item.EnvioId.slice(-12),
        Estatus: (item.Estatus === 0 && 'Enviado') || (item.Estatus === 1 && 'Recibido') || (item.Estatus === 2 && 'Cancelado'),
        FechaEnvio: format(new Date(item.FechaEnvio),"dd/MM/yyyy HH:MM:SS"),
        FechaRecepcion: item.FechaRecepcion ? format(new Date(item.FechaRecepcion),"dd/MM/yyyy HH:MM:SS") : "Sin recibir",
        Guia: `${item?.Guia?.ProveedorDeGuia ? `${item?.Guia?.ProveedorDeGuia} - ${item?.Guia?.NumeroDeGuia}`: "Entrega personal"}`
    }))
}

export const manualDispatchDownloads = (data)=> {
    return data.map((item) => ({
        date: new Date(item.fulfill.createdAt).toLocaleString(),
        name: item.name,
        email: item.email,
    })) 
}

export const movementsDownloads = (data) => {
    return data.map((item) => ({
        Cliente: item?.customer?.email ?? "NA",
        Tipo: item?.type ?? "NA",
        Descripcion: item?.description ?? "NA",
        Fecha: format(new Date(item.created_at),"dd/MM/yyyy HH:MM:SS"),
        "Cant. Anterior": item?.metadata?.before_mod ?? "NA",
        "Cant. Posterior": item?.metadata?.after_mod ?? "NA",
        "Realizado Por": item?.user?.email ?? "NA",
    }))
}

export const usersByIntegrationDownloads = (data) => {
    return data.map((rowData) => ({
        Usuario: rowData.userIntegrations.length > 0 ? rowData?.userIntegrations[0]?.name : "N/A",
        Email: rowData.userIntegrations.length > 0 ? rowData.userIntegrations[0]?.email : "N/A",
        Nombre: rowData?.name ?? "",
        Tipo: rowData?.type ?? ""
    }))
}

export const outboundsDownloads = (data) => {
    return data.map((item) => ({
        CodigoEnvio: item?.EnvioId?.slice(-12),
        Estatus: (item.Estatus === 0 && "Programado") || (item.Estatus === 1 && "Enviado"),
        FechaEnvio: format(new Date(data?.FechaEnvio), "dd/MM/yyyy HH:MM:SS"),
    }))
}