import React, {useContext, useEffect, useState} from 'react';
import {Button, Card, Col, Form, Input, Popconfirm, Row, Select, Tooltip} from 'antd';
import { useAuth0 } from '../../../hooks/react-auth0-spa';
import {actionCustomField, createCustomFields, getCustomFields} from '../../../api/ProductAPI';
import {Divider} from "@material-ui/core";
import {useAlert} from "react-alert";
import {EyeInvisibleOutlined, EyeOutlined} from "@ant-design/icons";
import {DeleteOutline} from "@material-ui/icons";
import {DataContext} from "../../../context/dataContext";

const fieldTypes = [
    { label: 'Texto', value: 'string' },
    { label: 'Número', value: 'number' },
    { label: 'Fecha', value: 'date' },
];

function CustomFields() {
    const [customFields, setCustomFields] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [editingField, setEditingField] = useState(null);
    const [apiCustomFields, setApiCustomFields] = useState([]);
    const [inventoryId, setInventoryId] = useState('');
    const [whOptions, setWhOptions] = useState([]);

    const alert = useAlert();
    const [form] = Form.useForm();
    const { getTokenSilently, user } = useAuth0();
    const { userWarehouses } = useContext(DataContext);

    const onFinish = (values) => {
        setInventoryId(values.inventoryId);
        delete values.inventoryId
        console.log('Values: ', values);
        if (editingField !== null) {
            const updatedFields = customFields.map((field) =>
                field.id === editingField ? { ...field, ...values } : field
            );
            setCustomFields(updatedFields);
            setEditingField(null);
        } else {
            const newField = { ...values, visible: true, id: Math.floor(Math.random() * 1600).toString() };
            setCustomFields((prevFields) => [newField, ...prevFields]);
        }
        form.resetFields(['fieldName', 'type']);
    };

    const onEdit = (field) => {
        form.setFieldsValue(field);
        setEditingField(field.id);
    };

    const onDelete = (id) => {
        setCustomFields((prevFields) => prevFields.filter((field) => field.id !== id));
    };

    const onSave = async() => {
        setIsLoading(true)
        const token = await getTokenSilently();
        const response = await createCustomFields(token, {
            UsuarioId: user,
            CustomFields: [...apiCustomFields, ...customFields],
            InventoryId: inventoryId,
        })
        // console.log('api response -->',response)
        if(response.status === 200){
            setIsLoading(false)
            setCustomFields([])
            await getAllCustomFields()
            return alert.success("¡Guardado correctamente!")
        }
        setIsLoading(false)
    }

    const getAllCustomFields = async () => {
        setIsLoading(true);
        const token = await getTokenSilently();
        const response = await getCustomFields(token, user.sub, inventoryId);
        // console.log('Response customFields: ', response)
        if (response.status === 200) {
            setApiCustomFields(response.data.CustomFields);
            setIsLoading(false);
            return
        }
        setApiCustomFields([])
        setIsLoading(false);
    };

    const actionApiCustomField = async(customId, actionType) => {
        setIsLoading(true);
        const token = await getTokenSilently();
        const response = await actionCustomField(token, user.sub, customId, actionType, inventoryId);
        console.log("Response customFields -> ",response)
        // if(response.status === 200) {
        //     setApiCustomFields(response.data.CustomFields);
        // }
        getAllCustomFields()
    }

    const formatWarehouseOptions = async() => {
        setIsLoading(true)
        if(userWarehouses){
            const warehouseOptions = userWarehouses?.map(item => {
              return { label: item.Title, value: item._id };
            })
            warehouseOptions.push({ label: 'Default ( Drevfill, Inactivos )', value: 'default' })
            setWhOptions(warehouseOptions)
            // console.log('[ GET - DATA CONTEXT ] Response: ', userWarehouses)
        }
        setIsLoading(false)
    }

    useEffect(() => {
        formatWarehouseOptions()
        getAllCustomFields();
    }, [inventoryId, userWarehouses]);//eslint-disable-line

    console.log('Inventory Id: ', inventoryId)

    return (
        <Card>
            <h3 style={{marginTop: '0'}}>Campos Personalizados</h3>
            <p>Agrega campos únicos para los productos en tus inventarios personalizados o en los predeterminados de la plataforma. Adapta tus inventarios a tus necesidades específicas.</p>
            <Divider style={{margin: '20px 0'}}></Divider>
            <Form onFinish={onFinish} layout="vertical" form={form}>
                <Row gutter={[10, 10]}>
                    <Col span={24}>
                        <Form.Item
                            label="Seleccionar Inventario"
                            name="inventoryId"
                            required
                            rules={[{required: true, message: 'El inventario es requerido'}]}
                        >
                            <Select placeholder="Seleccionar Inventario" options={whOptions} onChange={(e) => setInventoryId(e)}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="fieldName"
                            label="Nombre del campo"
                            rules={[{required: true, message: 'El nombre del campo es requerido'}]}
                        >
                            <Input placeholder="Ej: Fecha de vencimiento"/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name="type"
                            label="Tipo de dato"
                            rules={[{required: true, message: 'El tipo de campo es requerido'}]}
                        >
                            <Select style={{width: '100%'}} placeholder="Tipo" options={fieldTypes}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Button
                            style={{width: '100%', marginTop: '31.8px'}}
                            type="primary"
                            htmlType="submit"
                        >
                            {editingField !== null ? 'Actualizar' : 'Agregar'}
                        </Button>
                    </Col>
                </Row>
            </Form>
            {isLoading ? (
                <p>Cargando ...</p>
            ) : customFields.length > 0 ? (
                customFields.map((item) => (
                    <Row key={item.id} gutter={[10, 10]} style={{marginTop: '10px'}}>
                        <Col span={10}>
                            <Input disabled value={item.fieldName}/>
                        </Col>
                        <Col span={6}>
                            <Input disabled value={(item.type === 'string' && 'Texto' )|| (item.type === 'number' && 'Numérico') || (item.type === 'date' && 'Fecha')}/>
                        </Col>
                        <Col span={4}>
                            <Button type="link" onClick={() => onEdit(item)}>
                                Editar
                            </Button>
                        </Col>
                        <Col span={4}>
                            <Button type="link" danger onClick={() => onDelete(item.id)}>
                                Eliminar
                            </Button>
                        </Col>
                    </Row>
                ))
            ) : (
                <p>Agrega un nuevo campo personalizado.</p>
            )}
            {customFields.length > 0 && (
                <Row gutter={[10, 10]} style={{marginTop: '20px'}}>
                    <Col span={6}>
                        <Button style={{width: '100%'}} type="primary" onClick={onSave}>
                            GUARDAR
                        </Button>
                    </Col>
                    <Col span={6}>
                        <Button
                            style={{width: '100%'}}
                            type="primary"
                            danger
                            onClick={() => setCustomFields([])}
                        >
                            CANCELAR
                        </Button>
                    </Col>
                </Row>
            )}
            <Divider style={{margin: '20px 0'}}></Divider>
            <h3 style={{marginTop: '0'}}>Tus campos Personalizados</h3>
            {isLoading
                ? (<p>Cargando ...</p>)
                : apiCustomFields.length > 0
                    ? (
                        apiCustomFields.map((item) => (
                            <Row key={item.id} gutter={[10, 10]} style={{marginTop: '10px'}}>
                                <Col span={14}>
                                    <Input disabled value={item.fieldName}/>
                                </Col>
                                <Col span={6}>
                                    <Input disabled value={(item.type === 'string' && 'Texto') || (item.type === 'number' && 'Numérico') || (item.type === 'date' && 'Fecha')}/>
                                </Col>
                                <Col span={2}>
                                    <Tooltip title={item.visible ? "Ocultar de los productos" : "Mostrar en los productos"}>
                                        <Button type="primary" icon={item.visible ? <EyeInvisibleOutlined /> : <EyeOutlined />} onClick={() => actionApiCustomField(item.id, 'visible')} />
                                    </Tooltip>
                                </Col>
                                <Col span={2}>
                                    <Tooltip title="Eliminar campo">
                                        <Popconfirm title="¿Estas seguro de eliminar este campo para siempre?" onConfirm={() => actionApiCustomField(item.id, 'delete')}>
                                            <Button type="primary" icon={<DeleteOutline />} danger />
                                        </Popconfirm>
                                    </Tooltip>
                                </Col>
                            </Row>
                        ))
                    ) : (<p>No hay campos personalizado.</p>)
            }
        </Card>
    );
}

export default CustomFields;
