import React from "react";
import Home from "./home";

const Index = () => {
  return (
    <Home />
  );
};

export default Index;
