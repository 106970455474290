import React, {useEffect, useState} from 'react';
import {Card, Col, Row, Tooltip} from "antd";
import {useAuth0} from "../../hooks/react-auth0-spa";
import { axiosBase as axios } from "../../api/AxiosConfig";

function ProductsOutOfStock(props) {

    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const getBlinkClass = (quantity) => {
        if (quantity <= 4) return 'blink-red';
        if (quantity > 4 && quantity < 8) return 'blink-orange';
        return 'blink-green'; // Sin efecto si no cumple las condiciones
    };

    const { getTokenSilently } = useAuth0()

    const fetchData = async () => {
        setIsLoading(true)
        const token = await getTokenSilently()
        const headers = { Authorization: `Bearer ${token}` }

        try {
            const response = await axios.get('/statistics/productsOutOfStock', { headers })
            if(response.data){
                setData(response.data)
            }
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log('Error at ProductsOutOfStock: ', error)
        }
    }

    console.log('Data: ', data)

    useEffect(() => {
        fetchData()
    },[])//eslint-disable-line

    return (
        <Col span={8}>
            <Card title={<p style={{margin: '0', padding: '0', fontSize: '14px', fontWeight: 'bold'}}>Productos por agotarse</p>}>
                <Row>
                    <Col span={17} style={{margin: "auto", fontWeight: "bold", textAlign: "left" }}>
                        <p style={{ margin: '0', padding:'0', fontSize:'15px', fontWeight:'bold' }}>
                            Producto
                        </p>
                    </Col>
                    <Col span={7} style={{ margin: "auto", fontWeight: "bold", textAlign: "right" }}>
                        <p style={{ margin: '0', padding:'0', fontSize:'15px', fontWeight:'bold' }}>
                            Cantidad
                        </p>
                    </Col>
                </Row>
                <Row gutter={[0, 10]} style={{ maxHeight: "40vh", overflowY: "auto", marginTop:'10px'}}>
                    {
                       isLoading ? (<p style={{ textAlign:'center', color:'gray' }}>Cargando ...</p>)
                       : data.length > 0
                           ? data.map((item) => (
                            <React.Fragment key={item?.SKUCliente}>
                                <Col span={17} style={{ margin: "auto", textAlign: "left" }}>
                                    <Tooltip title={`SKU Del producto: ${item?.SKUCliente ?? "NA"}`}>
                                        {item?.Nombre ?? "NA"}
                                    </Tooltip>
                                </Col>
                                <Col span={7} style={{ margin: "auto", textAlign: "right" }}>
                                    <p className={`cell ${getBlinkClass(item?.CantidadEnInventario)}`} style={{ width:'fit-content', marginLeft:'auto', marginRight:'15px', borderRadius:'auto' }}>
                                        {item?.CantidadEnInventario ?? "0"}
                                    </p>
                                </Col>
                            </React.Fragment>
                        )) : (
                            <p style={{ textAlign:'center', color:'gray', width:'100%' }}>No hay datos para mostrar</p>
                        )
                    }
                </Row>
                {/*<Table columns={columns} dataSource={data ?? []} loading={isLoading} pagination={{ pageSize: 5 }} />*/}
            </Card>
        </Col>
    );
}

export default ProductsOutOfStock;