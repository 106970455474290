import {
    BarcodeOutlined,
    SendOutlined, TruckOutlined,
} from "@ant-design/icons"
import {Card, Col, Row} from "antd";
import { axiosBase as axios} from "../../api/AxiosConfig";
import {useEffect, useState} from "react";
import {useAuth0} from "../../hooks/react-auth0-spa";

const TopCards = () => {

    const [shipmentsToday, setShipmentsToday] = useState([])
    const [shipmentsCurrentMonth, setShipmentsCurrentMonth] = useState([])
    const [productsSentThisMonth, setProductsSentThisMonth] = useState(0)
    const { getTokenSilently, isAuthenticated } = useAuth0()

    // console.log('isAuthenticated: ', isAuthenticated)

    const getProductsSentCurrentMonth = async () => {
        const token = await getTokenSilently()
        const headers = { Authorization: `Bearer ${token}` }

        try {
            const response = await axios.get('/statistics/productsSelledCurrentMonth', { headers })
            if(response.data){
                setProductsSentThisMonth(response.data.reduce((acc, current) => acc + current.count, 0))
            }
        } catch (error) {
            console.log('Error at getProductsSentCurrentMonth: ', error)
        }
    }

    const fetchData = async () => {
        const token = await getTokenSilently()
        const headers = { Authorization: `Bearer ${token}` }
        try {
            const responseShipmentsToday = await axios("/statistics/shipmentsToday",{ headers })
            const responseShipmentsCurrentMonth = await axios("/statistics/shipmentsCurrentMonth",{ headers })

            if(responseShipmentsToday) setShipmentsToday(responseShipmentsToday.data[0])
            if(responseShipmentsCurrentMonth) setShipmentsCurrentMonth(responseShipmentsCurrentMonth.data[0])
        } catch (error) {
            console.log(error)
        }
    }

    // console.log('Respuestas shipmentsToday: ', shipmentsToday)
    // console.log('Respuestas shipmentsCurrentMonth: ', shipmentsCurrentMonth)
    // console.log('Respuestas productsSentThisMonth: ', productsSentThisMonth)

    useEffect(() => {
        if(isAuthenticated) {
            getProductsSentCurrentMonth()
            fetchData()
        }
    }, [getTokenSilently, isAuthenticated])//eslint-disable-line

    return (
        <Row gutter={[10,10]} className="w-full">

            <Col xs={24} sm={6} md={6} lg={5}>
                <Card style={{ margin: '0', padding: '0' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', margin:'0', padding:'0', alignItems: 'center' }}>
                        <p style={{ fontSize: '35px', margin:'0', padding:'0', fontWeight:'bold' }}>{ shipmentsToday?.shipmentsToday ?? 0 }</p>
                        <div style={{ background:'#2ca6bb', display:'flex', justifyContent:'center', padding:'10px', borderRadius:'10px' }}>
                            <SendOutlined style={{ fontSize:'25px', color:'#FFF' }} />
                        </div>
                    </div>
                    <p style={{ margin:'20px 0 0 0', padding:'0', fontSize:'15px', fontWeight:'bold' }}>Envíos hoy</p>
                </Card>
            </Col>

            <Col xs={24} sm={12} md={6} lg={5}>
                <Card style={{ margin: '0', padding: '0' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', margin:'0', padding:'0', alignItems: 'center' }}>
                        <p style={{ fontSize: '35px', margin:'0', padding:'0', fontWeight:'bold' }}>{ shipmentsCurrentMonth?.shipmentsThisMonth ?? 0 }</p>
                        <div style={{ background:'#d94a4a', display:'flex', justifyContent:'center', padding:'10px', borderRadius:'10px' }}>
                            <TruckOutlined style={{ fontSize:'25px', color:'#FFF' }} />
                        </div>
                    </div>
                    <p style={{ margin:'20px 0 0 0', padding:'0', fontSize:'15px', fontWeight:'bold' }}>Envíos este mes</p>
                </Card>
            </Col>

            <Col xs={24} sm={6} md={6} lg={5}>
                <Card style={{ margin: '0', padding: '0' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', margin:'0', padding:'0', alignItems: 'center' }}>
                        <p style={{ fontSize: '35px', margin:'0', padding:'0', fontWeight:'bold' }}>{ productsSentThisMonth ?? 0 }</p>
                        <div style={{ background:'#20b141', display:'flex', justifyContent:'center', padding:'10px', borderRadius:'10px' }}>
                            <BarcodeOutlined style={{ fontSize:'25px', color:'#FFF' }} />
                        </div>
                    </div>
                    <p style={{ margin:'20px 0 0 0', padding:'0', fontSize:'15px', fontWeight:'bold' }}>Productos vendidos mes</p>
                </Card>
            </Col>

        </Row>
    )
}

export default TopCards