import { useEffect, useState } from 'react'
import { axiosBase as axios } from '../../api/AxiosConfig'
import { useAuth0 } from "../../hooks/react-auth0-spa"
import TopCards from './TopCards'
import GraphList from './GraphList'

const WarehouseShipmentStatistics = ()=> {
    const [loading, setLoading] = useState(false)
    const [fetchingData, setFetchingData] = useState(null)
    const { getTokenSilently } = useAuth0()

    const handleFecthStatistics = async (statisticsType) => {
        setLoading(true)
        const userToken = await getTokenSilently()
        const { data } = await axios.post(
            `/statistics`, 
            statisticsType, 
            { headers: { Authorization: `Bearer ${userToken}` } }
        )  
        
        setFetchingData(data)
        setLoading(false)
    }

    useEffect(()=> {
        (async()=> {
            await handleFecthStatistics({
                products: false,
                warehouseShipments: true,
                outboundShipments: false
            })
        })()
    }, [])//eslint-disable-line

    return (
        <>
            <TopCards
                data={
                    [
                        {
                            title: 'Envios almacen hoy',
                            data: fetchingData?.res?.warehouseShipments?.totalWarehouseShipmentsPerDay ?? 0
                        },
                        {
                            title: 'Envios almacen mes',
                            data: fetchingData?.res?.warehouseShipments?.totalWarehouseShipmentsPerMonth ?? 0
                        },
                    ]
                }
                isLoading={loading}
            />
            <GraphList 
                data={
                    [
                        {
                            title: 'Envios almacen dia',
                            data: fetchingData?.res?.warehouseShipments?.warehouseShipmentsPerDay,
                            chartType: 'area',
                            filterType: 'day-count'
                        },
                        {
                            title: 'Envios almacen mes',
                            data: fetchingData?.res?.warehouseShipments?.warehouseShipmentsPerMonth,
                            chartType: 'bar',
                            filterType: 'month-count'
                        },
                    ]
                }
                isLoading={loading}
            />
        </>
    )
}

export default WarehouseShipmentStatistics
