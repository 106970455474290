import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { axiosBase } from '../../api/AxiosConfig';
import {useAuth0} from "../../hooks/react-auth0-spa";
import {Button, Table} from "antd";
import {format} from "date-fns";
import {IntegrationShipmentStatus} from "../../utils/States";
import {ArrowRightOutlined} from "@ant-design/icons";
import {useAlert} from "react-alert";
import RateModal from '../manualDispatch/RateModal';
import { DollarOutlined } from '@ant-design/icons';

function CustomerOrders({ integrationData, setModal }) {

    const [ordersData, setOrdersData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [filterOptions, setFilterOptions] = useState([])
    const { getTokenSilently } = useAuth0();
    const alert = useAlert()

    const getCustomerOrders = async(integrationInfo) => {
        setIsLoading(true)
        const token = await getTokenSilently();
        const response = await axios.get(
            `${process.env.REACT_APP_NOT_DRENVIO_BACKEND_URL}/integrations/orders?id=${integrationInfo._id}&limit=50&page=1&offset=0`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        )
        // console.log('Integracion seleccionada: ', integrationInfo)
        setModal({ open: integrationData.open, data: { ...integrationData.data, integrationInfo} })
        if(response.status === 200) {
            if(response.data.length === 0){
                alert.info('NO hay ordenes en esta tienda.')
                setModal({ data: {}, open: false })
            } else {
                const { data: orderResponse } = await axiosBase.get(
                    `/orderIntegrations`,
                    { headers: { Authorization: `Bearer ${token}` }}
                )
                // console.log('Order response -------------->', orderResponse)
                if(orderResponse.code !== 200){
                    alert.info('ha ocurrido un problema.')
                    setModal({ data: {}, open: false })
                } else {
                    const orderListIntegration = response.data
                    const orderListApi = orderResponse.res.map(item => item.order)
                    // console.log('orderListIntegration ------------>', orderListIntegration)

                    if(integrationInfo.type === 'MERCADOLIBRE'){
                        console.log('Entra aqui en mercadolibre')
                        const filteredOrderList = orderListIntegration.filter(item => {
                            if (!orderListApi.includes(item.order_number)){
                                return item
                            }
                            return null
                        })
                        setOrdersData(filteredOrderList)
                        setIsLoading(false)
                        return
                    }
                    setOrdersData(orderListIntegration)
                }
            }
        }
        setIsLoading(false)
    }

    const updateOrdersData = ()=> {
        setOrdersData([])
    }

    const table_orders_columns = [
        {
            title: 'Fecha de creación',
            dataIndex: '',
            render: (data) => (<p>{ data?.created_at ? format(new Date(data.created_at),"dd/MM/yyyy HH:MM:SS") : 'N/A' }</p>)
        },
        {
            title: '# de Pedido',
            dataIndex: 'order_number',
        },
        {
            title: 'Nombre',
            dataIndex: '',
            render: (data) => (<p>{ data?.customer_details?.name?.first_name ?? "N/A" }</p>)
        },
        {
            title: 'Calle',
            dataIndex: '',
            render: (data) => (<p>{ data?.shipping_address?.address ?? "N/A" }</p>)
        },
        {
            title: 'Ciudad',
            dataIndex: '',
            render: (data) => (<p>{ data?.shipping_address?.city ?? "N/A" }</p>)
        },
        {
            title: 'Estado/País',
            dataIndex: '',
            render: (data) => (<p>{ `${data?.shipping_address?.province?.name ?? ''}, ${data?.shipping_address?.country?.name ?? ''}` }</p>)
        },
        {
            title: 'Código Postal',
            dataIndex: '',
            render: (data) => (<p>{ data?.shipping_address?.zip_code ?? "N/A" }</p>)
        },
        {
            title: 'Estatus',
            dataIndex: '',
            render: (data) => (<div style={{width:'100%'}}>{ IntegrationShipmentStatus(data?.fulfillment_info?.status) }</div>),
            filters: filterOptions,
            onFilter: (value, record) => (record.fulfillment_info.status.indexOf(value) === 0)
        },
        {
            title: '',
            dataIndex: '',
            render: (data) => (
                <div>
                    <RateModal 
                        text="Cotizar"
                        Icon={<DollarOutlined />}
                        isFlexible={true}
                        data={integrationData.data}
                        integrationOrder={data}
                        updateOrdersData={updateOrdersData}
                    />
                </div>
            )
        },
        // {
        //     title: '',
        //     dataIndex: '',
        //     render: (data) => (
        //         <div>
        //             <RateModal
        //                 text="Cotizacion flexible"
        //                 Icon={<SwapOutlined />}
        //                 isFlexible={true}
        //                 data={integrationData}
        //                 integrationOrder={data}
        //                 updateOrdersData={updateOrdersData}
        //                 // integrationOrder={{
        //                 //     ...data,
        //                 //     product_items: [
        //                 //         {
        //                 //             "id": "sku123",
        //                 //             "name": "servo motor",
        //                 //             "total_price": 220,
        //                 //             "quantity": 1,
        //                 //             "variation": "MLM1166"
        //                 //         },
        //                 //         {
        //                 //             "id": "987",
        //                 //             "name": "Arduinooooooo",
        //                 //             "total_price": 220,
        //                 //             "quantity": 3,
        //                 //             "variation": "MLM1166"
        //                 //         },
        //                 //     ],
        //                 // }}
        //             />
        //         </div>
        //     )
        // },
        // {
        //     title: '',
        //     dataIndex: '',
        //     render: (data) => (
        //         <div>
        //             <RateModal
        //                 text="Cotizacion estricta"
        //                 Icon={<LockOutlined />}
        //                 isFlexible={false}
        //                 data={integrationData}
        //                 integrationOrder={data}
        //                 updateOrdersData={updateOrdersData}
        //                 // integrationOrder={{
        //                 //     ...data,
        //                 //     product_items: [
        //                 //         {
        //                 //             "id": "sku123",
        //                 //             "name": "servo motor",
        //                 //             "total_price": 220,
        //                 //             "quantity": 1,
        //                 //             "variation": "MLM1166"
        //                 //         },
        //                 //         {
        //                 //             "id": "987",
        //                 //             "name": "Arduinooooooo",
        //                 //             "total_price": 220,
        //                 //             "quantity": 3,
        //                 //             "variation": "MLM1166"
        //                 //         },
        //                 //     ],
        //                 // }}
        //             />
        //         </div>
        //     )
        // },
    ]

    const table_columns = [
        {
            title: 'Fecha de creación',
            dataIndex: "",
            render: (rowData) => (<p>{ rowData?.created_at ? format(new Date(rowData.created_at),"dd-MM-yyyy") : 'N/A' }</p>)
        },
        {
            title: "Nombre",
            dataIndex: "name",
        },
        {
            title: 'Fecha de actualización',
            dataIndex: "",
            render: (rowData) => (<p>{ rowData?.updated_at ? format(new Date(rowData.updated_at),"dd-MM-yyyy") : 'N/A' }</p>)
        },
        {
            title: 'Acciones',
            dataIndex: "",
            render: (rowData) => (<Button icon={<ArrowRightOutlined />} onClick={()=>getCustomerOrders(rowData)}>Ver ordenes</Button>)
        }
    ]

    const optionsFilters = () => {
        if(!integrationData.data.integrationInfo) return
        switch (integrationData?.data?.integrationInfo?.type) {
            case 'WOOCOMMERCE':
                setFilterOptions([
                    {
                        text: "Completado",
                        value: "completed"
                    },
                    {
                        text: "Cancelado",
                        value: "cancelled"
                    },
                    {
                        text: "Fallido",
                        value: "failed"
                    },
                    {
                        text: "Pendiente",
                        value: "pending"
                    },
                    {
                        text: "En espera",
                        value: "on-hold"
                    },
                    {
                        text: 'Procesando',
                        value: "processing"
                    }
                ])
                break;
            case 'MERCADOLIBRE':
                setFilterOptions([
                    {
                        text: "Entregado",
                        value: "shipped"
                    },
                    {
                        text: 'Procesando',
                        value: "ready_to_ship"
                    },
                    {
                        text: 'Enviado',
                        value: "delivered"
                    },
                    {
                        text: 'Cancelado',
                        value: "cancelled"
                    },
                ])
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        optionsFilters()
    },[integrationData?.data?.integrationInfo?.type])//eslint-disable-line

    return (
        <div style={{ overflow: 'scroll'}}>
            {
                ordersData.length === 0 ? (
                    <Table 
                        size='small'
                        loading={isLoading} 
                        columns={table_columns} 
                        dataSource={integrationData?.data?.integrations ?? []}
                        scroll={{ x: true }} 
                    />
                ) : (
                        <Table
                            size='small'
                            loading={isLoading}
                            columns={table_orders_columns}
                            dataSource={ordersData}
                            scroll={{ x: true }}
                        />
                )
            }
        </div>
    );
}

export default CustomerOrders;