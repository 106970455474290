import React, { useEffect, useState } from "react";
import { GetOutboundShipmentDetail } from "../../api/OutboundShipmentsAPI";
import { useAuth0 } from "../../hooks/react-auth0-spa";
import {
  Grid,
  Box,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import {Button, Card} from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";

const OutboundShipmentDetail = (props) => {
  const { getTokenSilently } = useAuth0();
  const setShipment = useState(undefined);
  const [products, setProducts] = useState(undefined);
  const History = useHistory();
  const alert = useAlert()

  async function fetchData() {
    const t = await getTokenSilently();
    const shippmentsResponse = await GetOutboundShipmentDetail(
      t,
      props.match.params.id
    );
    const infoProducts = shippmentsResponse.InfoEnvioProductos
    if(infoProducts.find(item => item.Producto === null)){
      History.push('/outboundshipments')
      if(infoProducts.length > 1){
        return alert.info('Algún producto de esta orden no existe.')
      }
      return alert.info('El producto de esta orden no existe.')
    }
    setShipment[1](shippmentsResponse);
    setProducts(infoProducts);
  }

  useEffect(() => {
    fetchData();
  }, []);//eslint-disable-line

  return (
    <Card>
      <Grid item style={{ display:'flex', justifyContent:'space-between', marginTop:'10px' }}>
        <Button type="primary" icon={<ArrowLeftOutlined />} onClick={()=>History.push('/outboundshipments')}>Ver todas las ordenes</Button>
      </Grid>
      {products && (
        <Grid item xs={12}>
          <Box mt={3} width={1}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Producto</TableCell>
                    <TableCell>Nombre</TableCell>
                    <TableCell>Cantidad</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {products.map((item, index) => (
                    <TableRow key={index} id={index}>
                      <TableCell>{item.Producto.SKUFulfillment.slice(-12)}</TableCell>
                      <TableCell>{item.Producto.Nombre}</TableCell>
                      <TableCell>{item.CantidadEnviada}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
          </Box>
        </Grid>
      )}
    </Card>
  );
};

export default OutboundShipmentDetail;
