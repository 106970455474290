
export const integrations = [
    {
        name: 'MERCADOLIBRE',
        status: true,
    },
    {
        name: 'WOOCOMMERCE',
        status: true,
    },
    {
        name: 'TIENDANUBE',
        status: false
    },
    {
        name: 'EBAY',
        status: false
    },
    {
        name: 'WIX',
        status: false
    },
    {
        name: 'AMAZON',
        status: false
    }
]