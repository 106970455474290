import { Button, Card, Modal } from "antd"
import { useEffect, useState, useContext } from "react"
import { useAlert } from "react-alert"
import { Steps } from 'antd'
import SelectedProducts from './SelectedProducts'
import FlexibleSelectedProductsByIntegration from './FlexibleSelectedProductsByIntegration'
import StrictSelectedProductsByIntegration from './StrictSelectedProductsByIntegration'
import { Quotes } from "../quoter/Quotes"
import {ArrowRightOutlined} from "@ant-design/icons";
import InputContainer from '../quoter/containers/InputContainer'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { QuoteDataContext } from '../../context/QuoteDataContext'


const RateModal = ({ data: user, integrationOrder, updateOrdersData, isFlexible, text, Icon, data })=> {

    const alert = useAlert()
    const [openModal, setOpenModal] = useState(false)
    const [userId, setUserId] = useState('')
    const [token, setToken] = useState('')
    const [step, setStep] = useState({ step: 0, description: 'select products' })
    const [shipment, setShipment] = useState(null)
    const [isFinish, setIsFinish] = useState(false)
    const { setQuoteStepTransition, quoteStep } = useContext(QuoteDataContext)
    const [integrationData, setIntegrationData] = useState({})

    const handleModal = ()=> setOpenModal(!openModal)

    const resetFields = () => {
        setUserId(user?.user)
        setToken(user?.ApiClientsV2[0]?.prod_token?.token)
        setStep({ step: 0, description: 'select products' })
        setShipment(null)
        setIsFinish(false)
    }

    const handleStep1 = () => setStep({ step: 1, description: 'rate' })
    const handleStep2 = () => setStep({ step: 2, description: 'generate' })
    const handleStep3 = () => handleFinish()
    const handleResumen = () => setStep({ step: 3, description: 'generate' })

    const handleFinish = ()=> {
        setIsFinish(true)
        setTimeout(()=> {
            handleModal()
        }, 2000)    
    }

    const handleShipment = (shipment)=> {
        if (!shipment || shipment?.InfoEnvioProductos.length === 0){
            alert.info("Debes seleccionar un producto")
            return 
        }

        setShipment(shipment)
    }

    useEffect(() => {
        setIntegrationData({orderData: integrationOrder, integrationInfo: data})
    }, [data, integrationOrder]);

    const handleTransition = (step)=> {
        switch(step){
            case 'select products':
                if (integrationOrder){
                    if (isFlexible){
                        return (
                            <FlexibleSelectedProductsByIntegration 
                                userId={userId}
                                handleShipment={handleShipment} 
                                handleStep={handleStep1}
                                integrationOrder={integrationData}
                            />
                        )
                    } else {
                        return (
                            <StrictSelectedProductsByIntegration 
                                userId={userId}
                                handleShipment={handleShipment} 
                                handleStep={handleStep1}
                                integrationOrder={integrationData}
                            />
                        )
                    }

                } else {
                    return (
                        <SelectedProducts 
                            userId={userId}
                            saveData={user} // data -> [ Ordenes de envio ]
                            handleShipment={handleShipment} 
                            handleStep={handleStep1}
                        />
                    )
                }

            case 'rate':
            case 'generate':
                return (
                    <Quotes
                        userId={userId}
                        token={token}
                        saveData={user}
                        shipment={shipment}
                        handleStep={handleStep2} 
                        handleStep3={handleStep3}
                        handleResumen={handleResumen}
                        handleFinish={handleFinish}
                        integrationOrder={integrationData}
                        updateOrdersData={updateOrdersData}
                    />
                )
            default:
                return
        }
    }

    useEffect(()=> {
        resetFields()
    }, [user, openModal])//eslint-disable-line

    return (
        <>
            <Button disabled={user.ApiClientsV2.length === 0 } icon={Icon ? Icon : <ArrowRightOutlined />} onClick={handleModal}>
                {text}
            </Button>
            <Modal
                open={openModal}
                title={false}
                width={'90%'}
                styles={{ body: { minHeight: '90vh', padding: '20px'}}}
                onCancel={handleModal}
                footer={false}
                maskClosable={false}
            >
                {/*<Card style={{ boxShadow:'0 0 10px 2px #0001', marginBottom: '25px' }}>*/}
                <div style={{ marginBottom: '25px' }}>
                    <Steps
                        current={step.step}
                        status={isFinish ? "finish" : "process"}
                        items={[
                            {
                                title: integrationOrder ? 'Validar productos' : 'Seleccionar producto',
                                // description: integrationOrder ? 'Verifica los artículos incluidos en el paquete' : 'Selecciona los productos que contiene el paquete',
                            },
                            {
                                title: 'Cotizar',
                                // description: 'Selecciona tu paqueteria favorita',
                            },
                            {
                                title: 'Generar guia',
                                // description: 'Genera tus guias a tus destinos favoritos',
                            },
                            {
                                title: 'Resumen',
                                // description: ''
                            }
                        ]}
                    />

                    {(step.step === 1 && quoteStep === '1) Quote based on Postal Codes') && (
                        <InputContainer
                            type="iconTitleHoverButton"
                            shape={'circle'}
                            title="Regresar"
                            iconOrReactElement={<ArrowLeftOutlined />}
                            style={{
                                position: 'absolute',
                                top: '50px',
                                right: '20px',
                                zIndex: '5'
                            }}
                            onClick={() => {
                                setStep({ step: 0, description: 'select products' })
                            }}
                        />
                    )}
                    
                    {(step.step === 2) && (
                        <InputContainer
                            type="iconTitleHoverButton"
                            shape={'circle'}
                            title="Regresar"
                            iconOrReactElement={<ArrowLeftOutlined />}
                            style={{
                                position: 'absolute',
                                top: '50px',
                                right: '20px',
                                zIndex: '5'
                            }}
                            onClick={() => {
                                setStep({ step: 1, description: 'rate' })
                                setQuoteStepTransition("1) Quote based on Postal Codes")
                            }}
                        />
                    )}
                </div>
                {/*</Card>*/}
                {/* <Card style={{ boxShadow:'0 0 10px 2px #0001' }}> */}
                    {handleTransition(step.description)}
                {/* </Card> */}
            </Modal>
        </>
    )
}

export default RateModal