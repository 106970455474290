import React, {useContext, useEffect, useState} from 'react';
import {Button, Col, Result, Row} from "antd";
import {CheckOutlined} from "@ant-design/icons";
import {QuoteDataContext} from "../../../context/QuoteDataContext";

function GuideSuccesScreen(props) {
    const { integrationOrder, shipment, handleStep3, updateOrdersData } = props

    const [products, setProducts] = useState([])

    const { resetQuoteStepTransition } = useContext(QuoteDataContext);

    console.log('Props: ', props)

    const onFinish = () => {
        resetQuoteStepTransition('1) Quote based on Postal Codes')
        handleStep3()
        if (integrationOrder?.orderData){
            updateOrdersData()
        }
    }

    useEffect(() => {
        setProducts(shipment.InfoEnvioProductos)
    },[])//eslint-disable-line

    return (
        <Row style={{ height:'100%', marginTop: '10rem' }}>
            <Col span={24}>
                <Result status="success" title={'¡Envío creado!'}/>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center' }}>
                    <p>Productos enviados: </p>
                    {
                        products.length > 0 ? products.map(product => (
                            <>
                                <p style={{ margin:'0'}}>{ product?.CantidadEnviada } - { product?.Nombre }</p>
                            </>
                        )) : (
                            <p>No hay productos que mostrar ...</p>
                        )
                    }
                    <div style={{ gap:'10px' }}>
                        <Button type="primary" icon={<CheckOutlined />} style={{ margin:'20px 5px', width:'15em' }} onClick={onFinish}>Finalizar</Button>
                    </div>
                </div>
            </Col>
        </Row>
    );
}

export default GuideSuccesScreen;